import {
  FETCH_LAYOUTS_PENDING,
  LAYOUTS_UPDATING,
  FETCH_LAYOUTS_SUCCESS,
  FETCH_LAYOUTS_ERROR,
  FETCH_USERLIST_PENDING,
  FETCH_USERLIST_SUCCESS,
  FETCH_USERLIST_ERROR,
  LAYOUT_REMOVED,
  LAYOUT_ADDED,
  LAYOUT_UPDATED,
  LAYOUT_UPDATED_CHANNEL,
  LAYOUT_UPDATED_SESSION,
  LAYOUT_ZOOM_UPDATED,
  LAYOUT_INFO,
  RESET_LAYOUT_GRID,
  SET_LAYOUT_VISUAL_MODE,
  SET_LAYOUT_LOCK,
  SHOW_ONLY_IN_LAYOUT,
  SET_CHANNEL_MODE,
  RESET_CHANNEL_MODE,
  SET_CHANNELS_MODE,
  LAYOUT_TOGGLED,
  LAYOUTS_TOGGLED,
  LAYOUTS_REMOVED,
  CHECK_LAYOUT_SOURCES,
  NO_ACTIVE,
  LAYOUT_REFRESHED,
  LAYOUT_CHANNEL_REMOVE,
  LAYOUT_SELECT_CHANNEL,
  LAYOUT_UNSELECT_CHANNEL,
  LAYOUT_RESET_INCLUDED_CAMERAS,
  TOGGLE_COVER_MODE,
  LAYOUT_RENAME,
  UPDATE_LAYOUTS_ORDER,
  ACTIVE_LAYOUT_UPDATE,
} from '../actions/types';

import { appPaths } from '../config/paths';
import { getAllDevices, getConnectedServersCodenames, getServerByCodeName, getServers, OmniaLight, isCredentialEnabled, getDeviceByArtecoId } from './serversReducer';
import { InstantPlayer, RecordingsSearch } from '../helpers/serverCredentials';

import { isIOS, isMobileOnly, isTablet } from 'react-device-detect';
import moment, { months } from 'moment';
import { getServerCodenameByArtecoid } from '../helpers/server';

const initialState = {
  pendingLayouts: false,
  pendingUserlist: false,
  showOnlyInLayout: false,
  layouts: [],
  includedCameras: [],
  userList: null,
  error: null,
  updatingLayouts: false,
  coverMode: false
}

  //get cover mode state from localstorage
  if (global.localStorage) {
    const coverModeState = global.localStorage.getItem('coverMode');        
    initialState.coverMode = (coverModeState === "true");
  }


var get = (name) => {
  if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(window.location.search))
    return decodeURIComponent(name[1]);
}

function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}
function removeChannelFromLayouts(layouts, channelId) {
  const channelFound = layouts.some((layout) => {
    return layout.channels.some((channel) => channel.artecoId === channelId);
  });

  if (!channelFound) {
    return layouts; // Return previous layouts if channelId not found
  }
  const updatedLayouts = layouts.map((layout) => {
    const updatedChannels = layout.channels.filter(
      (channel) => channel.artecoId !== channelId
    );
    return { ...layout, channels: updatedChannels };
  });
  return updatedLayouts;
}

const matchZoomAndPanSettings = (data) => {
  if (!global.localStorage) return data;

  const zoomAndPanSettings = global.localStorage.getItem('zoomAndPan');

  if (!zoomAndPanSettings) return data;

  const zoomAndPanLayouts = JSON.parse(zoomAndPanSettings);


  return data.map(extLayout => {
    const extLayoutZoomAndPan = zoomAndPanLayouts.find(layout => extLayout._id === layout._id);
    if (!extLayoutZoomAndPan) return {
      ...extLayout
    }

    const extLayoutChannelsZoomAndPan = extLayout.channels.map(channel => {
      const channelZoomAndPan = extLayoutZoomAndPan.channels.find(lschannel => channel.artecoId === lschannel.artecoId);

      if (!channelZoomAndPan) {
        return {
          ...channel
        }
      }

      return {
        ...channel,
        zoomAndPan: channelZoomAndPan.zoomAndPan
      }
    })

    return {
      ...extLayout,
      channels: extLayoutChannelsZoomAndPan
    }

  });
}

export function LayoutsReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_COVER_MODE:
      return {
        ...state,
        coverMode: action.payload
      }
    case CHECK_LAYOUT_SOURCES:
      return {
        ...state
      }
    case SHOW_ONLY_IN_LAYOUT:

      return {
        ...state,
        showOnlyInLayout: action.payload
      }
    case LAYOUT_ZOOM_UPDATED:
      let updatedZoomLayouts = state.layouts.map(layout => {
        if (layout._id !== action.payload._id)
          return layout;

        let zoomChannels = [];
        if (action.payload.channels) {
          zoomChannels = layout.channels.map((channel) => {
            const newZoomAndPan = action.payload.channels.find(originalChannel => originalChannel.artecoId === channel.artecoId).zoomAndPan;

            if (!newZoomAndPan) return channel;

            let newChannel = {
              ...channel,
              zoomAndPan: {
                live: newZoomAndPan.live ? newZoomAndPan.live : channel.zoomAndPan?.live,
                rec: newZoomAndPan.rec ? newZoomAndPan.rec : channel.zoomAndPan?.rec,
              }
            }
            return newChannel;
          })
        } else {
          zoomChannels = layout.channels
        }

        return {
          ...layout,
          channels: zoomChannels,
        }

      })

      if (global.localStorage) {
        localStorage.setItem('zoomAndPan', JSON.stringify(updatedZoomLayouts))
      }

      return {
        ...state,
        layouts: updatedZoomLayouts,
        pendingLayouts: false,
        updatingLayouts: false
      }
    case LAYOUT_UPDATED:
    case LAYOUT_UPDATED_SESSION:
      //console.log(`>>>--------------`);
      let updatedLayouts = state.layouts.map(layout => {
        if (layout && layout._id !== action.payload._id)
          return layout;

        let newChannels = [];

        if (action.payload.channels) {
          newChannels = action.payload.channels.map((channel) => {
            const channelfrom = layout.channels.find(originalChannel => originalChannel.id === channel.id)
            let newChannel = {
              ...channel,
              uFollowGui: channelfrom?.uFollowGui,
              mode: channelfrom !== undefined ? channelfrom.mode : undefined,
            }
            return newChannel;
          })
        } else {
          newChannels = layout.channels
        }

        return {
          ...layout,
          setActiveMap: action.payload.setActiveMap ? action.payload.setActiveMap : layout.setActiveMap,
          visible: action.payload.visible !== undefined ? action.payload.visible : layout.visible,
          popupMode: action.payload.popupMode !== undefined ? action.payload.popupMode : layout.popupMode,
          groupName: action.payload.groupName !== undefined ? action.payload.groupName : layout.groupName,
          showPeripherals: action.payload.showPeripherals !== undefined ? action.payload.showPeripherals : layout.showPeripherals,
          largePeripherals: action.payload.largePeripherals !== undefined ? action.payload.largePeripherals : layout.largePeripherals,
          channels: newChannels,
          peripherals: action.payload.peripherals ? action.payload.peripherals : layout.peripherals,
          isFromTag: action.payload.isFromTag !== undefined ? action.payload.isFromTag : layout.isFromTag,
          isSearchResult: action.payload.isSearchResult !== undefined ? action.payload.isSearchResult : layout.isSearchResult,
          searchResultMode: action.payload.searchResultMode !== undefined ? action.payload.searchResultMode : layout.searchResultMode,
          shareList: action.payload.shareList !== undefined ? action.payload.shareList : layout.shareList,
          pendingLayouts: false,
          updatingLayouts: false
        }

      })

      return {
        ...state,
        layouts: updatedLayouts,
        pendingLayouts: false,
        updatingLayouts: false
      }
    case LAYOUT_UPDATED_CHANNEL:
      const { layout: updatedLayout, channel: newChannel } = action.payload;

      const updatedChannelLayouts = state.layouts.map(layout => {
        if (layout._id !== updatedLayout._id) return layout;

        return {
          ...layout,
          channels: layout.channels.map(channel => {
            if (channel.artecoId !== newChannel.artecoId) return channel;
            return newChannel;
          })
        }
      })

      return {
        ...state,
        layouts: updatedChannelLayouts,
        pendingLayouts: false
      }
    case ACTIVE_LAYOUT_UPDATE: 
    const artecoIds = action.payload;
    return  {
      ...state,
      layouts: state.layouts.map(layout => layout._id == state.activeLayout ? ({
        ...layout,
        channels: layout.channels.map(channel => ({
          ...channel,
          uFollowGui: artecoIds.includes(channel.artecoId),
        })),
      }) : layout),
  
    }
    case SET_LAYOUT_VISUAL_MODE:
      let updatedLayoutsMap = state.layouts.map((layout, index) => {
        if (layout._id !== action.payload._id)
          return layout;

        layout.layoutMode = action.payload.layoutMode;
        return layout;
      });

      return {
        ...state,
        layouts: updatedLayoutsMap
      }

    case SET_LAYOUT_LOCK:
      let updatedLayoutsLock = state.layouts.map((layout, index) => {
        if (layout._id !== action.payload._id)
          return layout;

        layout.locked = action.payload.locked;
        return layout;
      });

      return {
        ...state,
        layouts: updatedLayoutsLock
      }
    case NO_ACTIVE:
      return {
        ...state,
        activeLayout: undefined,
        pendingLayouts: false,
        updatingLayouts: false
      }
    case LAYOUT_INFO:

      let layoutsWithInfo = [];
      if (state.layouts.length == 0) {
        layoutsWithInfo.push(action.payload);
      } else {

        layoutsWithInfo = state.layouts.map((layout, index) => {
          if (layout._id === action.payload._id) {
            layout.label = action.payload.label;

            if (action.payload.channels)
              layout.channels = action.payload.channels;

            if (action.payload.peripherals)
              layout.peripherals = action.payload.peripherals;

            layout.active = true;
            layout.visible = action.payload.visible !== undefined ? action.payload.visible : layout.visible;
            layout.visible = action.payload.visible !== undefined ? action.payload.visible : layout.visible;

          } else {
            layout.active = (layout.isFromTag === action.payload.isFromTag) ? false : layout.active;
          }
          return layout;
        })
      }


      const updatedState = {
        ...state,
        layouts: layoutsWithInfo,
        activeLayout: (action.payload.isFromTag) ? state.activeLayout : action.payload._id,
        activeTagLayout: (action.payload.isFromTag) ? action.payload._id : null,
        pendingLayouts: false,
        updatingLayouts: false
      }

      //is active layout stored also on DB?
      if (action.payload.stored) {
        updatedState.layoutStored = action.payload._id
      }

      return updatedState;
    
    case LAYOUT_RENAME: 
      const renameLayouts = state.layouts.map((layout, index) => {
        if (layout._id === action.payload._id) {
          layout.label = action.payload.label;
        }
        return layout;
      })
      
      return {
        ...state,
        layouts: renameLayouts
      }

    case LAYOUT_REFRESHED:

      let layoutsRefreshed = [];

      const { isNewShare, isStoppingShare } = action.payload;


      //new share - no layouts
      if (state.layouts.length == 0 && isNewShare) {
        layoutsRefreshed.push(action.payload);

        const refreshedState = {
          ...state,
          layouts: layoutsRefreshed,
          activeLayout: action.payload.layout._id,
          activeTagLayout: null,
          pendingLayouts: false,
          updatingLayouts: false
        }

        return refreshedState;
      }


      //new share
      if (isNewShare) {
        const newLayout = {
          ...action.payload.layout,
          active: true,
          locked: true,
          isMine: false,
          label: `*${action.payload.layout.label}`
        }

        layoutsRefreshed = state.layouts.concat(newLayout);

        const refreshedState = {
          ...state,
          layouts: layoutsRefreshed,
          activeLayout: action.payload.layout._id,
          activeTagLayout: null,
          pendingLayouts: false,
          updatingLayouts: false
        }

        return refreshedState;
      }

      //stop sharing
      if (isStoppingShare) {
        layoutsRefreshed = state.layouts.filter(layout => layout._id !== action.payload.layout._id)

        const refreshedState = {
          ...state,
          layouts: layoutsRefreshed,
          activeLayout: action.payload.layout._id,
          activeTagLayout: null,
          pendingLayouts: false,
          updatingLayouts: false
        }

        return refreshedState;
      }

      //update to an already shared layout
      layoutsRefreshed = state.layouts.map((layout, index) => {

        if (layout._id === action.payload.layout._id) {

          layout.label = action.payload.layout.label;

          if (action.payload.layout.channels)
            layout.channels = action.payload.layout.channels;

          if (action.payload.layout.peripherals)
            layout.peripherals = action.payload.layout.peripherals;

          layout.active = true;
          layout.visible = action.payload.layout.visible !== undefined ? action.payload.layout.visible : layout.visible;
          layout.layoutMode = action.payload.layout.layoutMode !== undefined ? action.payload.layout.layoutMode : layout.layoutMode;
          layout.setActiveMap = action.payload.layout.setActiveMap !== undefined ? action.payload.layout.setActiveMap : layout.setActiveMap;
          layout.popupMode = action.payload.layout.popupMode !== undefined ? action.payload.layout.popupMode : layout.popupMode;
          layout.groupName = action.payload.layout.groupName !== undefined ? action.payload.layout.groupName : layout.groupName;
          layout.showPeripherals = action.payload.layout.showPeripherals !== undefined ? action.payload.layout.showPeripherals : layout.showPeripherals;
          layout.largePeripherals = action.payload.layout.largePeripherals !== undefined ? action.payload.layout.largePeripherals : layout.largePeripherals;
          layout.peripherals = action.payload.layout.peripherals ? action.payload.layout.peripherals : layout.peripherals;
          layout.shareList = action.payload.layout.shareList !== undefined ? action.payload.layout.shareList : layout.shareList;
          layout.isMine = false;
          layout.locked = true;
          layout.label = `*${layout.label}`;
        }

        return layout;
      })

      const refreshedState = {
        ...state,
        layouts: layoutsRefreshed,
        activeLayout: action.payload.layout._id,
        activeTagLayout: null,
        pendingLayouts: false,
        updatingLayouts: false
      }

      return refreshedState;

    case UPDATE_LAYOUTS_ORDER:
      const layoutsOrder = action.payload;
      const orderedLayouts = layoutsOrder.map(order => {
        return state.layouts.find(layout => layout.id === order.id);
      });
    
      state.layouts.forEach(layout => {
        if (!layoutsOrder.some(order => order.id === layout.id)) {
          orderedLayouts.push(layout);
        }
      });

      return {
        ...state,
        layouts:  orderedLayouts
      }

    case LAYOUT_CHANNEL_REMOVE:
      const updatedLayoutsS = removeChannelFromLayouts(state.layouts, action.payload.artecoId);
      return {
        ...state,
        layouts: updatedLayoutsS,
      }

    case LAYOUT_ADDED:
      let layoutWithAddition = state.layouts.concat({ ...action.payload, isMine: true }).map(layout => {
        if (!action.payload.isFromTag && (layout._id !== action.payload._id)) {
          layout.active = false;
        }

        return layout
      });

      return {
        ...state,
        layouts: layoutWithAddition,
        activeLayout: !action.payload.isFromTag ? action.payload._id : state.activeLayout,
        activeTagLayout: action.payload.isFromTag ? action.payload._id : state.activeTagLayout
      }

    case FETCH_LAYOUTS_PENDING:
      return {
        ...state,
        pendingLayouts: true
      }
    case LAYOUTS_UPDATING:
      return {
        ...state,
        updatingLayoutss: true
      }
    case FETCH_USERLIST_PENDING:
      return {
        ...state,
        pendingLayouts: true
      }
    case FETCH_USERLIST_SUCCESS:
      return {
        ...state,
        pendingUserlist: false,
        userList: action.payload
      }
    case FETCH_LAYOUTS_SUCCESS:
      let activeLayout = null;

      //check if we have an active layout on request
      const activeLayoutRequest = get('activeLayout');
      //url without querystring
      const url = window.location.href.split('?')[0]
      let localLayoutSettings = false;
      let sessionActiveLayout = undefined;

      //read current active layout on DB
      let activeLayoutOnDB = undefined;
      action.payload.layouts.map(layout => {
        if (layout.active) {
          activeLayoutOnDB = layout._id;
        }

        return layout;
      });

      if (activeLayoutRequest) {

        //remove get param from querystring
        window.history.replaceState({}, document.title, url);

        //save on local storage we have a local setting
        if (global.localStorage) {
          global.localStorage.setItem('localLayoutSettings', true);
        }

        //save activeLayout on session storage
        if (global.sessionStorage) {
          global.sessionStorage.setItem('activeLayout', activeLayoutRequest);
        }

        const extLayoutZoomandPan = matchZoomAndPanSettings(action.payload.layouts);
        return {
          ...state,
          pendingLayouts: false,
          updatingLayouts: false,
          activeLayout: activeLayoutRequest,
          layoutStored: activeLayoutOnDB,
          layouts: extLayoutZoomandPan.map(layout => {
            const isMine = action.payload.userId === layout.owner;
            const checklayout = {
              ...layout,
              isMine: isMine,
              visible: !isMine ? false : layout.visible,
              locked: !isMine ? true : layout.locked,
              label: isMine ? layout.label : `*${layout.label}`
            }

            if (checklayout._id !== activeLayoutRequest) return checklayout;
            return {
              ...checklayout,
              visible: true
            }
          })
        }
      }

      //check if we have local layout settings
      if (global.localStorage) {
        localLayoutSettings = global.localStorage.getItem('localLayoutSettings');
      }

      if (localLayoutSettings && global.sessionStorage) {
        sessionActiveLayout = global.sessionStorage.getItem('activeLayout');

        //we have an active layout for this tab
        if (sessionActiveLayout && sessionActiveLayout !== 'undefined') {
          const extLayoutZoomandPan = matchZoomAndPanSettings(action.payload.layouts);
          return {
            ...state,
            pendingLayouts: false,
            updatingLayouts: false,
            activeLayout: sessionActiveLayout,
            layoutStored: activeLayoutOnDB,
            layouts: extLayoutZoomandPan.map(layout => {
              const isMine = action.payload.userId === layout.owner;
              const checklayout = {
                ...layout,
                isMine: isMine,
                visible: !isMine ? false : layout.visible,
                locked: !isMine ? true : layout.locked,
                label: isMine ? layout.label : `*${layout.label}`
              }
              if (checklayout._id !== sessionActiveLayout) return checklayout;
              return {
                ...checklayout,
                visible: true
              }
            })
          }
        }
      }


      //else retrieve active layout from DB
      let extLayouts = action.payload.layouts.map(layout => {
        const isMine = action.payload.userId === layout.owner;
        if (layout.active && isMine) {
          activeLayout = layout._id;
        }

        return layout;
      });


      //retrieve zoom      
      const extLayoutZoomandPan = matchZoomAndPanSettings(extLayouts);

      return {
        ...state,
        pendingLayouts: false,
        updatingLayouts: false,
        activeLayout: activeLayout,
        layoutStored: activeLayoutOnDB,
        layouts: extLayoutZoomandPan.map(layout => {
          const isMine = action.payload.userId === layout.owner;
          return {
            ...layout,
            isMine: isMine,
            visible: !isMine ? false : layout.visible,
            locked: !isMine ? true : layout.locked,
            label: isMine ? layout.label : `*${layout.label}`
          }
        })
      }
    case FETCH_LAYOUTS_ERROR:
      return {
        ...state,
        pendingLayouts: false,
        updatingLayouts: false,
        error: action.error
      }
    case FETCH_USERLIST_ERROR:
      return {
        ...state,
        pendingUserlist: false,
        error: action.error
      }
    case LAYOUT_REMOVED:
      const { newActiveLayout, deletedLayout } = action.payload;

      const strippedLayouts = state.layouts.filter((item) => item._id !== deletedLayout._id);

      const newActiveLayouts = strippedLayouts.map((layout) => {
        return {
          ...layout,
          active: newActiveLayout._id === layout._id
        }
      })

      return {
        ...state,
        pendingLayouts: false,
        updatingLayouts: false,
        error: action.error,
        deleted: deletedLayout,
        layouts: newActiveLayouts,
        activeLayout: newActiveLayout._id
      }
    case LAYOUTS_TOGGLED:

      const newActiveLayoutId = action.payload.newActiveLayoutId;
      const layoutsToBeUpdated = action.payload.layouts;
      const layoutsToBeUpdatedIdList = layoutsToBeUpdated.map(layout => layout._id);

      const layoutsAfterMultipleToggle = state.layouts.map(layout => {

        if (!layoutsToBeUpdatedIdList.includes(layout._id)) return layout;

        let layoutToUpdate = layoutsToBeUpdated.find(updatedLayout => layout._id === updatedLayout._id);
        return { 
          ...layoutToUpdate,
          groupName: layout.groupName
        }
         
      })

      return {
        ...state,
        pendingLayouts: false,
        updatingLayouts: false,
        error: action.error,
        layouts: layoutsAfterMultipleToggle,
        activeLayout: newActiveLayoutId
      }

    case LAYOUTS_REMOVED:

      const newActiveLayoutIdAfterRemoval = action.payload.newActiveLayoutId;
      const layoutsToBeRemoved = action.payload.layouts;
      const layoutsToBeRemovedIdList = layoutsToBeRemoved.map(layout => layout._id);

      const layoutsAfterMultipleRemoval = state.layouts.filter(layout => {
        if (!layoutsToBeRemovedIdList.includes(layout._id)) return true;
        return false;
      })

      const layoutsAfterMultipleRemovalActiveUpdated = layoutsAfterMultipleRemoval.map(layout => {
        if (layout._id !== newActiveLayoutIdAfterRemoval) return layout;

        return {
          ...layout,
          active: true
        }
      })

      
      return {
        ...state,
        pendingLayouts: false,
        error: action.error,
        layouts: layoutsAfterMultipleRemovalActiveUpdated,
        activeLayout: newActiveLayoutIdAfterRemoval
      }

    case LAYOUT_TOGGLED:
      //const activeLayoutAfterToggle = action.payload.newActiveLayout;
      const layoutToBeUpdated = action.payload.affectedLayout;

      const layoutsAfterToggle = state.layouts.map(layout => {
        if (layout._id !== layoutToBeUpdated._id) return layout;
        return layoutToBeUpdated
      })


      return {
        ...state,
        pendingLayouts: false,
        error: action.error,
        layouts: layoutsAfterToggle,
      }
    case RESET_LAYOUT_GRID: {
      return {
        ...state,
        resetGrid: action.payload
      }
    }
    case SET_CHANNEL_MODE: {

      let setLayouts = state.layouts.map(layout => {
        if (layout._id !== state.activeLayout)
          return layout;

        const updatedChannels = layout.channels.map(channel => {
          if (channel.artecoId === action.payload.artecoId) {
            return {
              ...channel,
              mode: action.payload.mode
            }
          }
          return channel;
        })

        return {
          ...layout,
          channels: updatedChannels
        }

      })

      return {
        ...state,
        layouts: setLayouts,
        pendingLayouts: false,
        updatingLayouts: false
      }
    }
    case SET_CHANNELS_MODE: {
      let setAllLayouts = state.layouts.map(layout => {
        if (layout._id !== state.activeLayout)
          return layout;
        const serverCodenames = action.payload.servers.map(server => server.codeName);
        const updatedAllChannels = layout.channels.filter(channel => serverCodenames.includes(channel.serverCodename)).map(channel => {
          const server = action.payload.servers.find(server => server.codeName === channel.serverCodename);
          if (isCredentialEnabled(InstantPlayer, server)) {
            return {
              ...channel,
              mode: action.payload.mode
            }
          } else {
            return {
              ...channel,
            }
          }

        })
        return {
          ...layout,
          channels: updatedAllChannels
        }

      })

      return {
        ...state,
        layouts: setAllLayouts,
        pendingLayouts: false,
        updatingLayouts: false
      }
    }
    case RESET_CHANNEL_MODE: {
      let resetLayouts = state.layouts.map(layout => {
        if (!layout.active)
          return layout;

        const updatedChannels = layout.channels.map(channel => {
          return {
            ...channel,
            mode: action.payload.mode
          }
        })

        return {
          ...layout,
          channels: updatedChannels
        }

      })

      return {
        ...state,
        layouts: resetLayouts,
        pendingLayouts: false,
        updatingLayouts: false
      }
    }
    case LAYOUT_SELECT_CHANNEL: {
      const newChannelArtecoId = action.payload.camera;
      return {
        ...state,
        includedCameras: state.includedCameras.concat(newChannelArtecoId) 
      }
    }
    case LAYOUT_UNSELECT_CHANNEL: {
      const channelArtecoIdToRemove = action.payload.camera;
      const updatedIncludedCameras = state.includedCameras.filter(camArtecoId => camArtecoId !== channelArtecoIdToRemove);
      return {
        ...state,
        includedCameras: updatedIncludedCameras
      }
    }
    case LAYOUT_RESET_INCLUDED_CAMERAS: {            
      return {
        ...state,
        includedCameras: []
      }
    }
    default:
      return state;
  }
}

export const getAllLayouts = state => state.layouts;
export const getAllVisibleLayouts = (state) => {
  const visibleLayouts = {
    ...state.layouts,
    layouts: state.layouts.layouts.filter(layout => layout.visible)
  }
  return visibleLayouts;
}

const convertedLayouts = (layouts) => {
  return layouts.map(layout => (layout ? { ...layout, layoutMode: 'grid', popupMode: { ...layout.popupMode, active: false } } : layout))
}

const stripFreeMode = (layouts, single = false) => {
  if (isTablet || isMobileOnly) {

    if (single) {
      return layouts ? {
        ...layouts,
        layoutMode: layouts.layoutMode === 'free' ? 'grid' : layouts.layoutMode
      } : layouts
    }

    return {
      ...layouts,
      layouts: layouts.layouts.map(layout => (layout ? { ...layout, layoutMode: layout.layoutMode === 'free' ? 'grid' : layout.layoutMode, popupMode: { ...layout.popupMode, active: false } } : layout))
    }
  }

  return layouts;
}



//tutti i layout visibili e invisibili ESCLUSI quelli da tag
export const getLayouts = (state) => {
  const isOmniaLight = OmniaLight(state);
  const standardLayouts = {
    ...state.layouts,
    layouts: state.layouts.layouts.filter(layout => !layout.isFromTag)
  }

  return !isOmniaLight ? stripFreeMode(standardLayouts) : {
    ...state.layouts,
    layouts: convertedLayouts(standardLayouts.layouts.filter(layout => layout.isMine))
  };
}

//tutti i layout visibili ESCLUSI quelli da tag
export const getVisibleLayouts = (state) => {
  const isOmniaLight = OmniaLight(state);

  const visibleLayouts = {
    ...state.layouts,
    layouts: state.layouts.layouts.filter(layout => layout.visible && !layout.isFromTag)
  }

  return !isOmniaLight ? stripFreeMode(visibleLayouts) : {
    ...state.layouts,
    layouts: convertedLayouts(visibleLayouts.layouts.filter(layout => layout.isMine))
  };
}

//tutti i layout quelli da tag visibili
export const getVisibleTagLayouts = (state) => {
  const isOmniaLight = OmniaLight(state);

  const visibleTagLayouts = {
    ...state.layouts,
    layouts: state.layouts.layouts.filter(layout => layout.visible && layout.isFromTag)
  }

  return !isOmniaLight ? stripFreeMode(visibleTagLayouts) : {
    ...state.layouts,
    layouts: convertedLayouts(visibleTagLayouts.layouts.filter(layout => layout.isMine))
  };
}

export const getSelectedTags = (state) => {

  const visibleTagLayouts = {
    ...state.layouts,
    layouts: state.layouts.layouts.filter(layout => layout.visible && layout.isFromTag && !layout.isSearchResult)
  }

  const selectedTags = visibleTagLayouts.layouts.map(layout => layout.label.split("_")[0]).sort();
  return selectedTags;
}

export const getLayoutGroups = (state) => {
  const layouts = getLayouts(state)?.layouts;

  const justMyLayouts = layouts.filter(layout => layout.isMine);
  const isOmniaLight = OmniaLight(state);

  const now = moment(new Date().toUTCString());
  const startDate = now.clone().subtract(7, 'days');

  const sharedLayouts = !isOmniaLight && layouts.filter(layout => !layout.isMine).map(layout => {
    return {
      ...layout,
      groupName: '_|_SharedWithMe_|_'
    }
  });

  const mostLessUsedLayouts = layouts
  .filter(layout => (layout.changeStatusDate && (layout.changeStatusDate !== null || layout.changeStatusDate !== "")))
  .map(layout => {
    const mydate = moment(layout.changeStatusDate);
    const isInLastWeek = (mydate <= now && mydate >= startDate);

    if (isInLastWeek) {
      return {
        ...layout,
        groupName: '_|_MOST_USED_|_'
      }
    } else {
      return {
        ...layout,
        groupName: '_|_LESS_USED_|_'
      }
    }
  });

  const allLayouts = !isOmniaLight ? justMyLayouts.concat(mostLessUsedLayouts,sharedLayouts) : justMyLayouts.concat(mostLessUsedLayouts);

  if (!layouts.length) return {};
    
  return groupBy(allLayouts, 'groupName');
  }

export const getLayoutGroupsNames = state => {
  const layouts = getLayouts(state)?.layouts;
  const groupNames = [...new Set(layouts.map(layout => { return layout.isMine ? layout.groupName || '' : '_|_SharedWithMe_|_' }))];
  
  if (!groupNames.includes("")) {
    groupNames.push("");
  }

  return groupNames.sort();

};
export const getLayoutsPending = state => state.layouts.pendingLayouts;
export const getLayoutsUpdating = state => state.layouts.updatingLayouts;
export const isActiveLayoutStored = state => (state.layouts.layoutStored === state.layouts.activeLayout);
export const getStoredLayout = state => state.layouts.layoutStored;
export const getLayoutsError = state => state.error;


//tagBehaviour = 0 || undefined -> escludi i layout creati da tag
//tagBehaviour = 1 -> includi i layout creati da tag
//tagBehaviour = 2 -> includi SOLO i layout creati da tag
export const getActiveLayout = (state, tagBehaviour = 0) => {
  let activeLayout = null;
  state.layouts.layouts.map((layout) => {

    const layoutToMatch = tagBehaviour === 2 ? state.layouts.activeTagLayout : state.layouts.activeLayout;

    if (layout._id === layoutToMatch) {

      switch (tagBehaviour) {
        case 0:
        case undefined:
          activeLayout = !layout.isFromTag ? layout : null;
          break;
        case 1:
          activeLayout = layout;
          break;
        case 2:
          activeLayout = layout.isFromTag ? layout : null;
          break;
        default:
          break;
      }

    }

    return layout
  })

  const isOmniaLight = OmniaLight(state);
  return (!isOmniaLight || !activeLayout) ? stripFreeMode(activeLayout, true) : {
    ...activeLayout,
    layoutMode: 'grid'
  }
};

export const getEdgeCamerasInLayout = (state, layout) => {

  let listOfEdgeCameras = [];

  if(layout == undefined || layout == null){
    return listOfEdgeCameras;
  }

  (Array.isArray(layout.channels) && layout.channels.length > 0) && layout.channels.forEach(channelLayout => {
    const currentDevice = getDeviceByArtecoId(state, channelLayout.artecoId);
    if (currentDevice != undefined && currentDevice.showOnvifRemoteTrack == 1) {
      listOfEdgeCameras.push(currentDevice);
    }
  })
  return listOfEdgeCameras
}

export const getActiveLayoutVisualMode = (state, tagBehaviour = 0) => {
  let activeLayout = getActiveLayout(state, tagBehaviour);
  return activeLayout?.layoutMode;
};

export const getActiveLayoutLock = (state, tagBehaviour = 0) => {
  let activeLayout = getActiveLayout(state, tagBehaviour);
  return activeLayout?.isMine ? activeLayout?.locked : true;
};

export const getActiveLayoutChannels = (state, tagBehaviour = 0) => {
  let activeLayout = getActiveLayout(state, tagBehaviour);
  let elements = [];
  if (activeLayout !== null) elements = activeLayout.channels;
  return elements || [];

};

export const getActiveLayoutChannelsIds = (state, tagBehaviour = 0) => {
  const channels = getActiveLayoutChannels(state, tagBehaviour);
  const includeConnectingServers = true;
  const connectedServersCodenames = getConnectedServersCodenames(state, includeConnectingServers);
  
  return channels.filter(channel => connectedServersCodenames.includes(channel.serverCodename)).map(channel => channel.artecoId);
}

export const getActiveLayoutPTZs = (state, tagBehaviour = 0) => {
  const channels = getActiveLayoutChannels(state, tagBehaviour);

  const serverDevices = getAllDevices(state);

  if (!serverDevices) return [];
  if (!channels || !Array.isArray(channels) || channels.length === 0) return [];

  return channels.filter(channel => {
    const found = serverDevices[channel.artecoId];
    if (!found) return false;
    return found["is-dome"];
  });
}

export const getActiveLayoutPeripherals = (state, tagBehaviour = 0) => {
  let activeLayout = getActiveLayout(state, tagBehaviour);
  let elements = [];

  if (activeLayout !== null) elements = activeLayout.peripherals;

  return elements || [];
};

export const getActiveLayoutElements = (state, tagBehaviour = 0) => {
  let activeLayout = getActiveLayout(state, tagBehaviour);
  let elements = [];


  if (activeLayout !== null) {

    elements = activeLayout.channels;
    elements = elements.concat(activeLayout.peripherals);

  }

  return elements || [];
}

export const getActiveLayoutServerCodenames = (state, tagBehaviour = 0) => {
  const elements = getActiveLayoutElements(state, tagBehaviour);

  const servers = [...new Set(elements.map(element => element.serverCodename))];

  return servers;
}

export const getActiveHLSServersIds = (state, tagBehaviour = 0) => {
  const elements = getActiveLayoutElements(state, tagBehaviour);

  const servers = [...new Set(elements.filter(element => element.streamMode === 'hls').map(element => element.serverCodename))];

  const serverIds = servers.map(serverCodename => {
    const server = getServerByCodeName(state, serverCodename);

    return server._id;
  });

  return serverIds;
}

export const getChannel = (state, artecoId) => {
  let activeChannels = getActiveLayoutElements(state);
  if (!Array.isArray(activeChannels) || activeChannels.length === 0) return undefined;

  const channel = activeChannels.find(channel => channel.artecoId === artecoId);

  return channel;
}

export const getChannelMode = (state, artecoId, tagBehaviour = 0) => {
  const channel = getChannel(state, artecoId);

  let mode = channel && channel.mode;

  return mode || {
    mode: 'live',
    from: undefined,
    to: undefined
  };
}


export const getChannelStreamMode = (state, artecoId, tagBehaviour = 0) => {
  const channel = getChannel(state, artecoId);

  let streamMode = channel && channel.streamMode;

  return streamMode || 'webrtc';
}

export const getChannelPrivacyShown = (state, artecoId, tagBehaviour = 0) => {
  const channel = getChannel(state, artecoId);

  let privacyShown = channel && channel.privacyShown;

  return privacyShown;
}

export const getChannelMetadataShown = (state, artecoId, tagBehaviour = 0) => {
  const channel = getChannel(state, artecoId);

  let metadataShown = channel && channel.metadataShown;

  return metadataShown;
}

export const getMinAndMaxProfile = (profiles) => {
  let maxProfile = profiles.reduce((max, profile) => max.quality > profile.quality ? max : profile);
  let minProfile = profiles.reduce((min, profile) => min.quality <= profile.quality ? min : profile);

  return {
    max: maxProfile.id,
    min: minProfile.id
  }
}

export const getBestProfile = (profiles, selectedProfile) => {
  if (selectedProfile === undefined) {
    return profiles[0].id;
  }

  const bestProfile = profiles.find(profile => profile.id === selectedProfile);

  return bestProfile ? bestProfile.id : getBestProfile(profiles, selectedProfile - 1)
}


export const getChannelWebRTCStreamProfile = (state, artecoId, device = null) => {
  const channel = getChannel(state, artecoId);
  if (!channel && ! device) return undefined;

  let fallbackSelectedWebrtcProfile = 0;
  const hasProfiles = device && device.profiles && Array.isArray(device.profiles) && device.profiles.length > 0;
  if(!channel) {
    const profiles = hasProfiles && getMinAndMaxProfile(device.profiles);
    fallbackSelectedWebrtcProfile = profiles.min;
  }
  
  let webrtcStreamProfile = channel?.webrtcStreamProfile ? parseInt(channel?.webrtcStreamProfile) : fallbackSelectedWebrtcProfile;

  //check if this profile is available
  if (hasProfiles) {
    webrtcStreamProfile = getBestProfile(device.profiles, webrtcStreamProfile);
  }

  return webrtcStreamProfile;
}

export const getChannelWebRTCDelayAmount = (state, artecoId, device = null) => {
  const channel = getChannel(state, artecoId);
  if (!channel) return undefined;

  let webrtcDelayAmount = undefined;
  webrtcDelayAmount = channel.webrtcDelayAmount ? parseFloat(channel.webrtcDelayAmount) : undefined;

  return webrtcDelayAmount;
}

export const getChannelZoomAndPan = (state, artecoId, env, tagBehaviour = 0) => {
  const isRecordings = env === appPaths.recordings;

  let zoomAndPan = {};
  const channel = getChannel(state, artecoId);
  if (!channel) return zoomAndPan;

  zoomAndPan = isRecordings ? channel.zoomAndPan?.rec : channel.zoomAndPan?.live;

  return zoomAndPan;
}

export const getChannelsMode = (state, tagBehaviour = 0) => {
  let activeChannels = getActiveLayoutElements(state, tagBehaviour);
  if (!Array.isArray(activeChannels) || activeChannels.length === 0) return undefined;

  var check = activeChannels.map((el, index) => { return (el.mode && el.mode.mode !== 'live') ? 1 : 0 })
  var atLeastOneInstant = check.reduce((a, b) => a + b)


  return atLeastOneInstant ? 'instant' : 'live';
}


export const gridShouldReset = (state) => {
  return state.layouts.resetGrid ? state.layouts.resetGrid : false;
}

export const getActiveLayoutId = (state, tagBehaviour = 0) => {
  if (tagBehaviour === 2) {
    return state.layouts.activeTagLayout ? state.layouts.activeTagLayout : null;
  }
  return state.layouts.activeLayout ? state.layouts.activeLayout : null;
}

export const showingOnlyInLayout = (state) => {
  return state.layouts.showOnlyInLayout;
}


export const channelIsPresent = (serverCodenames, servers, channel) => {
  if (!channel) return false;

  const serverIsConnected = serverCodenames.includes(channel.serverCodename);

  if (!serverIsConnected) return false;

  const server = servers.find(server => server.codeName === channel.serverCodename);

  if (!server || !server.channels || server.channels.length === 0) return false;

  return server.channels.find(serverChannel => serverChannel.artecoId === channel.artecoId);

}

export const recordingSearchCapability = (servers, channel) => {
  if (!channel) return false;
  const server = servers.find(server => server.codeName === channel.serverCodename);
  return isCredentialEnabled(RecordingsSearch, server) && channel

}

export const peripheralIsPresent = (serverCodenames, servers, peripheral) => {

  const serverIsConnected = serverCodenames.includes(peripheral.serverCodename);

  if (!serverIsConnected) return false;

  const server = servers.find(server => server.codeName === peripheral.serverCodename);

  if (!server || !server.peripherals || server.peripherals.length === 0) return false;

  return server.peripherals.find(serverChannel => serverChannel.artecoId === peripheral.artecoId);

}


export const arePeripheralsShown = (state) => {
  let activeLayout = getActiveLayout(state);
  return activeLayout ? activeLayout.showPeripherals : false;
}

export const arePeripheralLarge = (state) => {
  let activeLayout = getActiveLayout(state);
  return activeLayout ? activeLayout.largePeripherals : false;
}

export const getUserlistPending = state => {
  return state.layouts.pendingUserlist;
}


export const getLayoutUserList = (state) => {
  const userList = state.layouts.userList;
  return userList
}

export const getActiveLayoutShareList = (state) => {
  let activeLayout = getActiveLayout(state);
  return (activeLayout && activeLayout.shareList) ? activeLayout.shareList : [];
}

export const getLayoutById = (state, layoutId) => {
  const layouts = getLayouts(state);
  return layouts.filter(layout => layout._id === layoutId);
}

export const isLayoutShared = (state, layoutId) => {
  const layout = getLayoutById(state, layoutId);

  return layout && layout.shareList && Array.isArray(layout.shareList) ? layout.shareList.length : false;
}

export const isActiveLayoutShared = (state) => {
  let activeLayout = getActiveLayout(state);

  return activeLayout && activeLayout.shareList && Array.isArray(activeLayout.shareList) ? activeLayout.shareList.length : false;
}

export const getPinsInSight = (peripherals) => {
  if (!peripherals || !Array.isArray(peripherals) || peripherals.length === 0) return 0;

  return peripherals.map(peripheral => {
    if (peripheral.visiblePins && Array.isArray(peripheral.visiblePins)) {
      return peripheral.visiblePins.length
    }

  }).reduce((a, b) => a + b, 0);
}

export const getActiveLayoutCamsWithPrivacy = (state) => {
  const channels = getActiveLayoutChannels(state);

  const serverDevices = getAllDevices(state);

  if (!serverDevices) return [];
  if (!channels || !Array.isArray(channels) || channels.length === 0) return [];

  return channels.filter(channel => {
    const found = serverDevices[channel.artecoId];
    if (!found) return false;
    return found.privacyPlgEnabled;
  });  
}

export const getIncludedCameras = (state) => {
  return state.layouts.includedCameras;
}

export const hasChannelsInActiveLayout = (state, serverOrSite) => {
  const channels = getActiveLayoutChannels(state);
  if (!channels || !Array.isArray(channels) || channels.length === 0)
    return false;

  // a serverList (a site) can be provided to check if anyCamera of this site is added to the current layout
  // the logic is the same, so I'm just overloading the existent function, handling the case the second param is an array
  const isSite = Array.isArray(serverOrSite);
  const codenames = isSite ? serverOrSite.map(server => server.codeName) : [];

  return channels.some((channel) => {
    const parentServerCodeName = getServerCodenameByArtecoid(channel.artecoId);
    return isSite ? codenames.includes(parentServerCodeName) : parentServerCodeName === serverOrSite.codeName;
  });
};

export const hasPeripheralsInActiveLayout = (state, serverOrSite) => {
  const peripherals = getActiveLayoutPeripherals(state);
  if (!peripherals || !Array.isArray(peripherals) || peripherals.length === 0)
    return false;

  // a serverList (a site) can be provided to check if anyCamera of this site is added to the current layout
  // the logic is the same, so I'm just overloading the existent function, handling the case the second param is an array
  const isSite = Array.isArray(serverOrSite);
  const codenames = isSite ? serverOrSite.map(server => server.codeName) : [];

  return peripherals.some((peripheral) => {
    const parentServerCodeName = getServerCodenameByArtecoid(
      peripheral.artecoId
    );

    return isSite ? codenames.includes(parentServerCodeName) : parentServerCodeName === serverOrSite.codeName;
  });
};

export const getCoverModeState = (state) => {
  return state.layouts.coverMode;
}

export const getIsAudioActiveByArtecoId = (state, artecoId) => {
  const channel = getChannel(state, artecoId);
  if (!channel) return undefined;
  let isAudioActive = channel && channel.isAudioActive;
  return isAudioActive;
}
