import React from "react";
import Clappr from "clappr";
import { Events, UICorePlugin } from 'clappr';
import ReactDOM from 'react-dom';
import { ReactComponent as SnapIcon } from './svg/capture.svg';
import { ExternalLink as PipIcon } from "react-feather";

import InstantPlayerPlugin from './InstantPlayerPlugin';
import AudioPlugin from './AudioPlugin';
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";

class PipButton extends React.Component {
  render() {
    return (
      <>
        <button type="button" className="media-control-button media-control-icon pip-button" aria-label="pip" onClick={this.props.children.onClick}>
          <PipIcon className="pip" />
        </button>
      </>
    )
  }
}

class SnapButton extends React.Component {
  render() {
    return (
      <>
        <button type="button" className="media-control-button media-control-icon pip-button" aria-label="pip" onClick={this.props.children.onClick}>
          <SnapIcon className="pip" />
        </button>
      </>
    )
  }
}


class ArtecoPlugins extends UICorePlugin {
  get attributes() {
    return {
      'class': 'arteco-plugins'
    }
  }

  constructor(core) {
    super(core);
    this.bindEvents();
  }

  goPip = () => {
    this.core.getCurrentPlayback().el.requestPictureInPicture();
  }

  bindEvents() {
    this.listenTo(this.core, Clappr.Events.CORE_ACTIVE_CONTAINER_CHANGED, this.reload);
    this.listenTo(this.core.mediaControl, Events.MEDIACONTROL_RENDERED, this.mediaRender);
  }

  unBindEvents() {
    this.listenTo(this.core, Clappr.Events.CORE_ACTIVE_CONTAINER_CHANGED, this.reload);
    this.stopListening(this.core.mediaControl, Events.MEDIACONTROL_RENDERED);
  }

  mediaRender() {
    this.render();
  }

  reload() {
    this.unBindEvents();
    this.bindEvents();
  }

  downAction = () => {
    var video = this.core.mediaControl.container.playback.el;
    var canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0);
    var base64 = canvas.toDataURL('image/jpeg');

    this.core.mediaControl.trigger('capture:base64', base64);

    this.downloadImage(base64, 'camera.jpeg');
  }


  downloadImage = (data, filename = 'camera.jpeg') => {

    var a = document.createElement('a');
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

  }

  render() {
    const { noPip, switchToInstantAction, switchToLiveAction, isPlayback, artecoId, allowOnlyPIP, exportVideoAction, exportStatus, downloadProgress, isDownloading, getFileAction, userPrefs, device,isPrivacyPopUpOn,isAudioActive, toggleAudio} = this.core._options;
    const PipProps = {
      onClick: this.goPip
    }

    const snapProps = {
      onClick: this.downAction
    }



    const InstantProps = {
      isPlayback,
      switchToInstantAction,
      switchToLiveAction,
      exportVideoAction,
      exportStatus,
      downloadProgress,
      isDownloading,
      getFileAction,
      userPrefs,
      device,
    }

    const AudioProps = {
      artecoId,
      isAudioActive,
      toggleAudio,
    }

    this.core.mediaControl.$('.media-control-right-panel').append(this.el);
    let pipButtonComponent = document.pictureInPictureEnabled && React.createElement(PipButton, { key: `pip_${artecoId}` }, PipProps);
    let snapButtonComponent = document.pictureInPictureEnabled && React.createElement(SnapButton, { key: `pip_${artecoId}` }, snapProps);
    let audioButtonComponent =  React.createElement(AudioPlugin, { key: `audio_${artecoId}` }, AudioProps);
    let ipButtonComponent = React.createElement(InstantPlayerPlugin, { key: `ip_${artecoId}` }, InstantProps);


    let Elements = [audioButtonComponent];

    if (isTablet || isMobileOnly || noPip) {
        Elements.push(allowOnlyPIP ? [] : [ipButtonComponent]);
    } else {
        Elements.push(isPrivacyPopUpOn ? [] : allowOnlyPIP ? [pipButtonComponent] : [ipButtonComponent, pipButtonComponent]);
    }

    let container = React.createElement('div', { key: 1, className: "arteco-plugins-inner" }, Elements);

    ReactDOM.render(
      container,
      this.el);
  }

  destroy() {
    super.destroy();
    ReactDOM.unmountComponentAtNode(this.el);
  }
}

ArtecoPlugins.type = 'core';

export default ArtecoPlugins;