import {
    SET_FILTERS,
    FETCH_FILTERS_SUCCESS,
    SHOW_FILTERS,
    SHOW_NAV_FILTERS
} from "../actions/types";
import { OmniaLight } from "./serversReducer";

const initialState = {
    filterGroups: [],
    filtersShown: false,
};

export function FiltersReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FILTERS_SUCCESS:
            return {
                ...state,
                filterGroups: action.payload,
                filterSaved: false
            };
        case SET_FILTERS:
            return {
                ...state,
                filterGroups: action.payload,
                filterSaved: true
            };
        case SHOW_FILTERS:
            return {
                ...state,
                showFilters: action.payload
            };
        case SHOW_NAV_FILTERS:
            return {
            ...state,
            filtersShown: action.payload,
            };

        default:
            return state;
    }
}

export const getFilters = (state) => {
    return state.filters;
}

export const getFilterSelected = (state) => {    
    const isOmniaLight = OmniaLight(state);

    let categories = [];
    let servers = [];
    let vcaClasses = [];
    let vcaColors = [];
    let intrusionStatus = [];
    let intrusionTypes = [];
    let onlyActiveLayoutEvents = false;
    
    if(isOmniaLight) {
        return {
            categories,
            servers,
            vcaClasses,
            vcaColors,
            intrusionStatus,
            intrusionTypes,
            onlyActiveLayoutEvents
        };    
    }

    // VCA -> vca
    // KSENIA -> Intrusion (alarm)
    // IMPRO -> Access Control

    state.filters.filterGroups.map((group) => {
        group.filters.map(filter => {
            if(filter.categories && filter.checked) {
                categories.push(...filter.categories);
            }
            /**Note:
             * the filter that display only the event belongin to the active layout devices was insert between the servers type 
             * just to display in the servers accordion (required by busines). for this reason it is exclude from 
             * logic of selected servers and is managed using the boolean onlyActiveLayoutEvents
             */
            if(filter.codeName && filter.codeName !== 'onlyInActiveLayoutButton' && filter.checked) servers.push(filter.codeName);
            if(filter.brand === 'vca' && filter.property === 'class' && filter.checked) vcaClasses.push(filter.value);
            if(filter.brand === 'vca' && filter.property === 'color' && filter.checked) vcaColors.push(filter.value); 
            if(filter.brand === 'ksenia' && filter.property === 'status' && filter.checked) intrusionStatus.push(filter.value); 
            if(filter.brand === 'ksenia' && filter.property === 'type' && filter.checked) intrusionTypes.push(filter.value); 
            if(filter.codeName === 'onlyInActiveLayoutButton' && filter.checked) onlyActiveLayoutEvents = true;

            return false;
        })
        
        return false;
    });
    return {
        categories,
        servers,
        vcaClasses,
        vcaColors,
        intrusionStatus,
        intrusionTypes,
        onlyActiveLayoutEvents
    };    
}

export const areFiltersShown = (state) => {  
    const isOmniaLight = OmniaLight(state);
    if(isOmniaLight) return false;
    
    return state.filters.showFilters ? state.filters.showFilters : false;
}

export const areNavFiltersShown = (state)=>{
    return state.filters.filtersShown ? state.filters.filtersShown : false;
}