import { ARTECO_SER_CODE } from "../actions/types";
import { CIVETWEB_ENTRYPOINT } from "../config/backend";
import { error, logger } from "./logger";
import { addDays } from "./timeHelpers";

const filterFunction = (event) => {
  if(event.eventData) {
    //KSENIA FILTERING
    if(event.eventData?.kseniaData) {
      const kseniaData = event.eventData?.kseniaData;
      if(kseniaData && kseniaData.TIME) return 0
    }
    
    return 1;
  }

  return 1;
}

export function processEvents(events, server, serverDevices, auth, visibility = null) {
  return events.map(event => {

    if (server.codeName !== event.serverCodename) {
      return false;
    }  


    let artecoId = `${event.serverCodename}_${event.deviceType}_${event.deviceId}`;
    let artecoEventId = `${event.serverCodename}_${event.deviceType}_${event.id}`;
    let device = serverDevices[artecoId];

    if (event.deviceType === ARTECO_SER_CODE || !device) {
      return false;
    }

    //AST-4015
    if(event.category === 301) {
      return false;
    }
    //AST-4015

    let thumbnailUrl = (event.thumbnailUri) ? event.thumbnailUri.replaceAll('\\', '/') : '';
    //nginx
    let thumbnailPath = '';
    if(thumbnailUrl && thumbnailUrl !== "none") {
      thumbnailPath = server.nodeServer ? `${server.protocol}://${server.ip}:${server.port}/thumbnails/${thumbnailUrl}` : `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/thumbnails/${thumbnailUrl}`;
    }
    
    //let thumbnailPath = `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/thumbnails/${thumbnailUrl}`;
    
    let retention_days = device["retention-days"];
    let delete_date_arteco = addDays(event.eventTime, retention_days);

    //TODO: pulirli lato server!!
    let eventParamsUpdate = event.category === 212 ? event.params.replace('\n', ' ') : event.params;


    let additionalData = event.eventData;

    if(event.eventData) {
      try {
        additionalData = JSON.parse(event.eventData);
        //MOCK
        //additionalData.transCam = '10';
        //additionalData.transCam = 'V03220059_4';
        //additionalData.relatedCameras = ['3e655da4a886561dcdc0cfc167170dc_1_10'];
      } catch (e) {
        logger(error, "events", "Error trying parsing additional data for event " + artecoEventId);
        additionalData = event.eventData;
      }
    }

    let newEvent = {
      ...event,
      id: parseInt(event.id),
      artecoId: artecoId,
      artecoEventId: artecoEventId,
      serverId: server._id,
      owner: auth.user.id,
      serverIp: server.ip,
      serverPort: server.port,
      serverProtocol: server.protocol,
      className: 'new',
      thumbnailUri: thumbnailPath,
      notes: event.notes,
      retention_days: retention_days,
      deleteDate: delete_date_arteco,
      params: event.category === 212 ? eventParamsUpdate.split(' ')[0] : event.params || '', //Filtro necessario per gli eventi targa in qunto dal server arrivano informazioni 'sporche' per gli eventi di tipo LPR
      eventBrand: event.eventBrand,
      eventData: additionalData,
      eventIntegrationType: event.eventIntegrationType,
      visibility: visibility
    }

    return newEvent;

  }).filter(filterFunction);
}

export function sortEventsByEventTimeAC(a,b,sortByEventTime,sortAC){

  const sortOrder = sortAC ? 1 : -1;
  const processedA = a.username !== '' && a.username !== 'System'; // if true = PROCESSED 
  const processedB = b.username !== '' && b.username !== 'System'; // if true = PROCESSED

  if(sortByEventTime){
    //If sortByEventTime == true i sorte all the events (processed or not) by eventTime
    const compareResult = a.eventTime.localeCompare(b.eventTime);
    return sortOrder * compareResult;

  }else{

    //RULES for sorting processed
    //if sortAC == TRUE => at the top, display the UNPROCESSED events sorted by the "eventTime" from the oldest to the most recent, followed by PROCESSED events sorted by "lastUpdateTime" from the oldest to the most recent.
    //if sortAC == FALSE => at the top, display the PROCESSED events sorted by "lastUpdateTime" from the most recent to the oldest, and immediately after, display the UNPROCESSED events sorted by "eventTime" from the most recent to the oldest.

    if (!processedA && !processedB) {
      //Both unprocessed, sort by eventTime
      return sortOrder * a.eventTime.localeCompare(b.eventTime);
    }

    if (!processedA) {
      //Only a is unprocessed, place it first 
      return -1 * sortOrder;
    }
    if (!processedB) {
      //Only b is unprocessed, place it first
      return 1 * sortOrder;
    }

    //Both processed, sort by lastUpdateTime
    return sortOrder * a.lastUpdateTime.localeCompare(b.lastUpdateTime);

  }
}

export function eventMatchsFilters(event,selectedFilters, serverDevices, activeLayoutChannelsIds,  live, lastStop, searchTerms,strict){

  const eventArtecoId = event.artecoId || `${event.serverCodename}_${event.deviceType}_${event.deviceId}`;
    
  //if the artecoId is not in the serverDevices, we don't want to show the event
  if(!serverDevices[eventArtecoId]) {
    return false;
  }
  
  if (noFiltersSet(selectedFilters, live, searchTerms)) {
    return true;
  }
  if (event.params === undefined || event.params === null) {
    event.params = "";
  }
  if (typeof (event.params.toUpperCase) !== 'function') {
    event.params = event.params.toString();
  }

  let textMatch = false;
  const queryNum = searchTerms.length;
  const isStrict = strict;

  const found = searchTerms.map(cleanSearchString => {
    let matches = 0;

    if (event.params.toUpperCase().includes(cleanSearchString.toUpperCase())) {
      matches++;
    }
    if (serverDevices[eventArtecoId] && serverDevices[eventArtecoId].descr.toUpperCase().includes(cleanSearchString.toUpperCase())) {
      matches++;
    }
    if (event.id.toString().includes(cleanSearchString)) {
      matches++
    }


    return {
      string: cleanSearchString,
      score: matches
    }
  })


  if (queryNum === 0) {
    textMatch = true;
  } else {
    let strictWin = false;

    let result = found.reduce((acc, obj) => { return acc + obj.score }, 0);
    let check = found.filter(singleString => singleString.score > 0);

    if (check.length === queryNum) {
      //tutti hanno almeno una vittoria
      strictWin = true;
    }

    textMatch = isStrict ? (result > 0 && strictWin) : result > 0;
  }
 
  const categoryFilters = selectedFilters.categories;
  const serverFilters = selectedFilters.servers;
  const activeLayoutArtecoIds = !selectedFilters.onlyActiveLayoutEvents ? [] : activeLayoutChannelsIds;
  
  const filtersMatch = (categoryFilters.length === 0) || categoryFilters.includes(event.category);
  const serversMatch = (serverFilters.length === 0) || ( serverFilters.includes(event.serverCodename)); 
  const activeLayoutArtecoIdsMatch = !selectedFilters.onlyActiveLayoutEvents || activeLayoutArtecoIds.includes(eventArtecoId);

  const timeMatch = (live) || (event.eventTime <= lastStop);
  // filtrers for third part integrations
  const vcaFilter = vcaFilterFunction(event,selectedFilters); // VCA
  const kseniaFilter = kseniaFilterFunction(event,selectedFilters); // KSENIA

  const output = textMatch && filtersMatch && serversMatch && timeMatch && vcaFilter && kseniaFilter && activeLayoutArtecoIdsMatch;
  
  return output;

}


export function vcaFilterFunction (event,selectedFilters){
  // vca forensich search !

  const vcaSmartSearchData = {
    classes: selectedFilters.vcaClasses,
    colors: selectedFilters.vcaColors,
    classConfidence: 1, // al momento sono cablati al minimo !
    colorConfidence: 1, // al momento sono cablati al minimo !
  }

  const filtersDisabled = (vcaSmartSearchData.classes.length == 0 && vcaSmartSearchData.colors.length == 0);

  if (filtersDisabled) {
    // non è un evento vca
    return true;
  }

  if (event?.eventData?.analytics == undefined) {
    return false;
  }

  let foundClass = true;
  let foundColors = true;

  if (vcaSmartSearchData.classes) {
    const minClassConfidenceLevel = vcaSmartSearchData.classConfidence / 100; // livello minimo di confidenza per un colore altrimenti ignoro !

    const classThreSholdList = event?.eventData?.analytics?.dlclassification.filter(classObj =>
      parseFloat(classObj.confidence) > minClassConfidenceLevel
    )
    const eventClassListNames = classThreSholdList.map(a => a.class.toLowerCase());
    const userClassFilterList = vcaSmartSearchData.classes.map(a => a.toLowerCase());

    foundClass = (userClassFilterList.length == 0) || userClassFilterList.some(r => eventClassListNames.indexOf(r) >= 0);

  }

  // colors filter
  if (vcaSmartSearchData.colors) {
    const minColorConfidenceLevel = vcaSmartSearchData.colorConfidence / 100; // livello minimo di confidenza per un colore altrimenti ignoro !

    const colorThreSholdList = event?.eventData?.analytics?.colourSignature.filter(colorObj =>
      parseFloat(colorObj.proportion) > minColorConfidenceLevel
    )

    const eventColorsListNames = colorThreSholdList.map(a => a.color.toLowerCase());
    const userColorsFilterList = vcaSmartSearchData.colors.map(a => a.toLowerCase());

    foundColors = (userColorsFilterList.length == 0) || userColorsFilterList.some(r => eventColorsListNames.indexOf(r) >= 0);
  }
  return foundClass && foundColors
}



const kseniaFilterFunction = (event,selectedFilters) => {

  const kseniaSmartSearchData = {
    states: selectedFilters.intrusionStatus,
    types: selectedFilters.intrusionTypes,
  }

  const filtersDisabled = (kseniaSmartSearchData.states.length == 0 && kseniaSmartSearchData.types.length == 0);

  if (filtersDisabled) {
    // non è un evento ksenia
    return true;
  }

  if (event?.eventData?.kseniaData == undefined) {
    return false;
  }

  let foundState = true;
  let foundType = true;

  if (kseniaSmartSearchData.states) {
    // states = categories
    const currentClass = event?.eventData?.category;
    foundState = kseniaSmartSearchData.states.includes(currentClass);

  }

  // c'è un problema quando il match del figlio viene vincolato dal padre, va verificato

  if (kseniaSmartSearchData.states) {
    // types = subCategory
    const currentType = event?.eventData?.subCategory;
    foundType = kseniaSmartSearchData.types.includes(currentType);

  }

  let logic = true;

  if(kseniaSmartSearchData.states.length > 0 && kseniaSmartSearchData.types.length == 0){ // gli stati sono stati selezionati
    logic = foundState;
  }

  if(kseniaSmartSearchData.states.length == 0 && kseniaSmartSearchData.types.length > 0){ // i tipi sono stati selezionati
    logic = foundType;
  }

  if(kseniaSmartSearchData.types.length > 0 && kseniaSmartSearchData.states.length > 0 ){ // entrambi sono stati selezionati
    logic = foundState && foundType;
  }

  return logic;

}

const noFiltersSet = (selectedFilters, live, searchTerms) => {

  if (selectedFilters.categories && selectedFilters.categories.length) return false;
  if (selectedFilters.servers && selectedFilters.servers.length) return false;

  if (selectedFilters.vcaClasses && selectedFilters.vcaClasses.length) return false;
  if (selectedFilters.vcaColors && selectedFilters.vcaColors.length) return false;
  
  if (selectedFilters.intrusionStatus && selectedFilters.intrusionStatus.length) return false;
  if (selectedFilters.intrusionTypes && selectedFilters.intrusionTypes.length) return false;


  if (selectedFilters.onlyActiveLayoutEvents ) return false;

  if (!live) return false;
  if (searchTerms.length) return false;

  return true;
}