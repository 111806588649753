import {
  UPDATE_INTEGRATIONS,
  UPDATE_INTEGRATION,
  SERVER_DISCONNECT,
  SERVER_REMOVED,
  ARTECO_PER_CODE,
  SELECT_INTEGRATION,
  DESELECT_INTEGRATION,
  REMOVE_LINKED_ARTECO_ID,
  SERVER_DISCONNECT_ALL
} from "../actions/types";
import { updateArraybyKey } from "../helpers/integrations";
import { getDeviceByArtecoId, retrieveArtecoId } from "./serversReducer";

const initialState = {
  integrations: [],
  selectedIntegrationId: null
};



export function IntegrationsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_INTEGRATION: {
      return {
        ...state,
        selectedIntegrationId: action.payload.integrationId
      }
    }
    case DESELECT_INTEGRATION: {
      return {
        ...state,
        selectedIntegrationId: null
      }
    }
    case REMOVE_LINKED_ARTECO_ID: {
      const updatedLinkedIntegrations = state.integrations.map(integration => {
        if (integration._id !== action.payload.integrationId) return integration;

        return {
          ...integration,
          linkedCameras: integration.linkedCameras.filter(camera => camera.artecoId !== action.payload.artecoId)
        }
      })
      return {
        ...state,
        integrations: updatedLinkedIntegrations
      }
    }
    case UPDATE_INTEGRATION: {

      const integrationBrand = action.payload.eventBrand;


      let processedIntegrations = null;
      let currentIntegrations = null;
      switch (integrationBrand) {
        case 'ksenia':
          currentIntegrations = getIntegrationsByBrand(state, integrationBrand, false);

          const updatedConfigData = action.payload?.eventData[`${integrationBrand}ConfigData`];
          const updatedLinkedCameras = action.payload?.eventData?.linkedCameras;
          const kseniaId = action.payload?.eventData?.kseniaId;

          // if(action.payload?.test) {
          //   debugger;
          // }

          processedIntegrations = currentIntegrations.map(integration => {
            if (integration._id !== kseniaId) return integration

            //in case of restart/start event, in integration.ksenia.kseniaConfigData we have a []

            let dataToReturn = updatedConfigData;

            if ((updatedConfigData && Array.isArray(updatedConfigData) && updatedConfigData.length === 0)) {
              dataToReturn = integration.ksenia.kseniaConfigData;
            }

            const updatedZones = dataToReturn?.STATUS_ZONES != undefined ? updateArraybyKey(integration.ksenia.kseniaConfigData?.STATUS_ZONES, dataToReturn?.STATUS_ZONES[0], "ID") : integration.ksenia.kseniaConfigData?.STATUS_ZONES;
            const updatedOutputs = dataToReturn?.STATUS_OUTPUTS != undefined ? updateArraybyKey(integration.ksenia.kseniaConfigData?.STATUS_OUTPUTS, dataToReturn?.STATUS_OUTPUTS[0], "ID") : integration.ksenia.kseniaConfigData?.STATUS_OUTPUTS;
            const updatedPartitions = dataToReturn?.STATUS_PARTITIONS != undefined ? updateArraybyKey(integration.ksenia.kseniaConfigData?.STATUS_PARTITIONS, dataToReturn?.STATUS_PARTITIONS[0], "ID") : integration.ksenia.kseniaConfigData?.STATUS_PARTITIONS;
            const updatedResult = dataToReturn?.RESULT != undefined ? dataToReturn?.RESULT : integration.ksenia.kseniaConfigData?.RESULT;
            const updatedResultDetail = dataToReturn?.RESULT_DETAIL != undefined ? dataToReturn?.RESULT_DETAIL : integration.ksenia.kseniaConfigData?.RESULT_DETAIL;

            const mergedData = {
              ...integration.ksenia.kseniaConfigData,
              STATUS_ZONES: updatedZones,
              STATUS_OUTPUTS: updatedOutputs,
              STATUS_PARTITIONS: updatedPartitions,
              RESULT_DETAIL: updatedResultDetail,
              RESULT:updatedResult,
            }

            return {
              ...integration,
              ksenia: {
                ...integration.ksenia,
                kseniaConfigData: mergedData
              },
              linkedCameras: updatedLinkedCameras || integration.linkedCameras
            }
          })

          break;

        default:
          processedIntegrations = currentIntegrations;
          break;
      }


      return {
        ...state,
        integrations: processedIntegrations || state.integrations
      }
    }
    case UPDATE_INTEGRATIONS:
      const { integrations, server } = action.payload;

      //processing integrations to recreate artecoId for linked cameras
      const processedIntegrations = integrations.map(integration => {
        const linkedCameras = integration.linkedCameras;
        if (!linkedCameras || linkedCameras.length === 0) return integration;

        return {
          ...integration,
          linkedCameras: linkedCameras.map(camera => {
            return {
              ...camera,
              artecoId: camera.ref
            }
          })
        }

      })

      //remove older integrations from this server
      const filteredIntegrations = state.integrations.filter(integration => integration.serverCodename !== server.codeName);
      const updatedIntegrations = [...processedIntegrations, ...filteredIntegrations];
      return {
        ...state,
        integrations: updatedIntegrations
      };
    case SERVER_DISCONNECT:
    case SERVER_REMOVED:

      const cleanIntegrations = state.integrations.filter(integration => integration.serverCodename !== action.payload.codeName);

      return {
        ...state,
        integrations: cleanIntegrations
      }
    case SERVER_DISCONNECT_ALL:      

      return {
        ...state,
        integrations: []
      }
    default:
      return state;
  }
}

export const getIntegrations = (state, complete = true) => {
  const integrations = state.integrations.integrations || state.integrations;

  const richIntegrations = complete ? integrations.map(integration => {
    return {
      ...integration,
      type: 'integration',
      artecoDevice: integration.artecoDeviceId && getDeviceByArtecoId(state, `${integration.serverCodename}_${ARTECO_PER_CODE}_${integration.artecoDeviceId}`)
    }
  }) : integrations;

  return richIntegrations;
}

export const getIntegrationsByBrand = (state, brand, complete = true) => {
  const integrations = getIntegrations(state, complete);

  const brandIntegrations = integrations.filter(integration => integration.brand === brand);

  if (!brandIntegrations || brandIntegrations.length === 0) return [];

  return brandIntegrations;
}

export const getIntegrationsByServer = (state, server, complete = true) => {
  const integrations = getIntegrations(state, complete);

  if (!integrations || integrations.length === 0) return [];

  return integrations.filter(integration => integration.serverCodename === server.codeName);
}

export const getIntegrationById = (state, _id, complete = true) => {
  const integrations = getIntegrations(state, complete);

  return integrations && integrations.find(integration => integration._id === _id);
}

export const getSelectedIntegrationId = (state) => {
  return state.integrations.selectedIntegrationId
}

function groupBy(array, getKey) {
  return array.reduce((accumulator, item) => {
    const key = getKey(item);
    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    accumulator[key].push(item);
    return accumulator;
  }, {});
}

export const getIntegrationsByArtecoId = (state, artecoId) => {
  const integrations = getIntegrations(state);

  const foundIntegrations = integrations.filter(integration => {
    const found = integration.linkedCameras.find(camera => camera.ref === artecoId);
    return !!found;
  })

  const integrationsResult = foundIntegrations && foundIntegrations.length > 0 ? groupBy(foundIntegrations, item => item.brand) : undefined;

  return integrationsResult;
}

export const getSelectedElementsByArtecoId = (state, artecoId) => {
  const selectedIntegration = getIntegrationById(state, getSelectedIntegrationId(state));

  const filterElements = selectedIntegration.linkedCameras.filter(camera => camera.artecoId === artecoId).map(integration => {
    const dictionary = selectedIntegration;

    const brand = dictionary.brand;
    const whereToSearch = dictionary[brand][`${brand}ConfigData`] && dictionary[brand][`${brand}ConfigData`][`${integration.type}S`];

    const result = whereToSearch && whereToSearch.length > 0 && whereToSearch.find(el => el.ID === integration.element);

    return result ? {
      ...result,
      type: integration.type
    } : integration
  });

  const groupedElements = groupBy(filterElements, item => item.type);

  return groupedElements;
}
