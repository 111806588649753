export const CamerasPrivacyBlur = "Cameras-Privacy-Blur"
export const CamerasTimestampOverlay = "Cameras-Timestamp-Overlay";
export const CamerasWatermarkOverlay = "Cameras-Watermark-Overlay"
export const ChatVideocall = 'Chat-Videocall';
export const ConfigurationAddCamera = 'Configuration-Add-Camera';
export const ConfigurationCameraGpsLocation = 'Configuration-Camera-GPS-location';
export const Events = 'Events';
export const InstantEvent = 'Instant-Event';
export const LayoutAddRemoveCameras = 'Layout-Add/Remove-Cameras';
export const LayoutAddRemovePeripherals = 'Layout-Add/Remove-Peripherals';
export const GoogleMaps = 'Layout-Google-Maps-View';
export const PeripheralsInteraction = 'Layout-Peripherals-Interaction';
export const PtzMovement = 'PTZ-Movement';
export const PtzPresets = 'PTZ-Presets';
export const PtzSequences = 'PTZ-Sequences';
export const RecordingEventPlayback = 'Recordings-Event-Playback'
export const ExportVideo = 'Recordings-Export-Video';
export const InstantPlayer = 'Recordings-Instant-player';
export const RecordingsSearch = 'Recordings-Search';
export const Tags = 'Tags'
export const CamerasTreeview = 'Treeview-Cameras';
export const PeripheralsTreeview = 'Treeview-Peripherals';
export const Integration = 'Integration';
export const AuditingSearch = "Auditing-Search";
export const AuditingUserTracking = "Auditing-User-Tracking"
export const ConfigurationServerSMTPEmailProvider="Configuration-Server-SMTP-Email-Provider";
export const UFollowConfiguration = "u-Follow-Configuration";
export const UFollowView = "u-Follow-View";