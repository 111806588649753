import { CIVETWEB_ENTRYPOINT } from "../config/backend";

import {
  FETCH_RECORDING_PENDING,
  FETCH_RECORDING_SUCCESS,
  REFRESH_RECORDING_EVTS,
  FETCH_RECORDING_ERROR,
  RESET_RECORDINGS_ERRORS,
  TIMELINE_MARKER,
  RESET_RECORDINGS,
  SET_EXPORT_INTERVAL,
  RESET_EXPORT_INFO,
  ARTECO_PER_CODE,
  SERVER_DISCONNECT,
  SET_TIMELINE_MODE,
  SET_SYNC_MODE,
  TOGGLE_TIMELINE_VISIBILITY,
  UPDATE_RECORDING_EVENT,
  UPDATE_RECORDING_EVENTS,
  SET_EDGE_MODE,
  SERVER_DISCONNECT_ALL,
} from '../actions/types';

import moment from 'moment';
import 'moment-timezone';

import { error, info, logger } from '../helpers/logger';
import { PRE_EVENT_TIME, POST_EVENT_TIME } from '../config/eventsConfig';
import { addSecondsToServerDate, convertUTCToLocal, intervalOperations, serverStringMajorEqual, serverStringMinor } from '../helpers/timeHelpers';
import { roundToNearestThreeOrZero } from "../helpers/server";
import { getServerByCodeName, isCredentialEnabled } from "./serversReducer";
import { Events } from "../helpers/serverCredentials";
import { sortEventsByEventTimeAC } from "../helpers/event";
import  {areEventsSortByEventTime,areEventsSortByAc} from "./eventsReducer"

const loggerScope = "recordingsReducer";

const initialState = {
  pendingRecording: 0,
  searchParams: {},
  recordings: {},
  errors: {},
  eventsThumbnails: [],
  thumbsDictionary: {},
  timelineMarker: undefined,
  exportStart: null,
  exportEnd: null,
  syncMode: false,
  timelineMode: 'stop',
  timelineVisible: false,
  edgeMode: false,
}

function updateRecording(recordings, event) {
  if (Object.keys(recordings).length === 0) {
    return {};
  }
  if(!event) {
    return recordings;
  }
  
  const { id, serverCodename } = event;
  const recording = recordings[serverCodename];
  if (!recording) {
    return recordings;
  }
  const updateEvent = event.deviceType === 1 ? recording.cleanEvents.find((e) => e.id === id) : recording.peripheralsEvents.find((e) => e.id === id);

  if (!updateEvent) {
    return recordings;
  }
  Object.assign(updateEvent, event);

  return recordings;
}

function filterFunction(event, includePeriperals = false) {
  let isGood = true;

  if(includePeriperals) {
    isGood = event.deviceType === ARTECO_PER_CODE;
  } else {
    isGood = event.deviceType !== ARTECO_PER_CODE;
  }

  if(isGood && event.eventData) {
    //KSENIA FILTERING
    if(event.eventData?.kseniaData) {
      const kseniaData = Array.isArray(event.eventData?.kseniaData) ? event.eventData?.kseniaData[0] : event.eventData?.kseniaData;
      isGood = kseniaData && kseniaData.TIME ? false : true;
    } 
  }

  if(isGood &&  event.category === 301) {
    isGood = false;
  }

  return isGood;
}


export function RecordingsReducer(state = initialState, action, server) {
  switch (action.type) {

    case RESET_RECORDINGS_ERRORS:

      return {
        ...state,
        errors: {}
      }

    case FETCH_RECORDING_PENDING:

      return {
        ...state,
        pendingRecording: state.pendingRecording + 1,
      }

    case RESET_RECORDINGS:

      return {
        ...state,
        pendingRecording: 0,
        searchParams: {},
        recordings: {},
        errors: {},
        eventsThumbnails: [],
        timelineMarker: undefined,
        exportStart: null,
        exportEnd: null,
        syncMode: false,
        timelineMode: 'stop',
        timelineVisible: false,
      }
      

    case UPDATE_RECORDING_EVENT:
      const serverCodename = action.payload.serverCodename;

      const updatedRecordings = {
        ...state.recordings
      }

      updatedRecordings[serverCodename].cleanEvents = updatedRecordings[serverCodename].cleanEvents.map(event => {
        const artecoEventId = `${event.serverCodename}_${event.deviceType}_${event.id}`;
        if (artecoEventId !== action.payload.artecoEventId) return event;

        return {
          ...event,
          color: action.payload.color !== undefined ? action.payload.color : event.color,
          state: action.payload.evtState !== undefined ? action.payload.evtState : event.state,
          favourite: action.payload.favourite !== undefined ? action.payload.favourite : event.favourite,
          notes: action.payload.currentNoteText ? event.notes ? event.notes.concat({ username: action.payload.username, text: action.payload.currentNoteText }) : [{ username: action.payload.username, note: action.payload.currentNoteText }] : event.notes,
        }
      })

      return {
        ...state,
        recordings: updatedRecordings
      }

    case UPDATE_RECORDING_EVENTS:
      const eventsUpdate = updateRecording(state.recordings, action.payload[0])
      return {
        ...state,
        recordings: eventsUpdate
      }

    case REFRESH_RECORDING_EVTS:

      const distributedEvents = {};
      action.payload.map(event => {
        const serverCodename = event.serverCodename;
        if (distributedEvents[serverCodename]) {
          distributedEvents[serverCodename]["cleanEvents"].push(event);
        } else {
          distributedEvents[serverCodename] = {};
          distributedEvents[serverCodename]["cleanEvents"] = [event];
        }
      });

      const updatedRecodingsState = state.recordings;

      const updatedServers = Object.keys(distributedEvents);
      if (updatedServers.length) {
        updatedServers.map(serverCodename => {
          updatedRecodingsState[serverCodename].cleanEvents = distributedEvents[serverCodename].cleanEvents;
        })
      }

      return {
        ...state,
        recordings: updatedRecodingsState
      }

    case FETCH_RECORDING_SUCCESS: // Ottengo la lista degli regeistrationi e la scompongo in 'eventi', 'registrazioni' e 'buchi di registrazione'.

      const currentServer = action.payload.server;
      const currentServerCodeName = currentServer.codeName;

      const currentSearch = action.search;
      const listOfthumbnailsOfEvents = []

      const payloadEvents = action.payload.events ? Array.isArray(action.payload.events.event) ? action.payload.events.event : [action.payload.events.event] : [];
      const payloadSessions = action.payload.sessions ? Array.isArray(action.payload.sessions.session) ? action.payload.sessions.session : [action.payload.sessions.session] : [];

      for (const currentSession of payloadSessions) { // aggiungo ad ogni sessione il suo artecoId
        currentSession.artecoId = `${currentSession.serverCodename}_${currentSession.deviceType}_${currentSession.deviceId}`
      }

      const newDictionary = {
        ...state.thumbsDictionary
      }

      for (const currentEvent of payloadEvents) { // aggiungo ad ogni sessione il suo artecoId

        const eventArtecoEventId = `${currentServerCodeName}_${currentEvent.deviceType}_${currentEvent.id}`;

        currentEvent.artecoId = `${currentServerCodeName}_${currentEvent.deviceType}_${currentEvent.deviceId}`
        //nginx
        if(currentEvent.thumbnailUri && currentEvent.thumbnailUri !== "none") {
          currentEvent.thumbnailUri = (currentServer.nodeServer) ? `${currentServer.protocol}://${currentServer.ip}:${currentServer.port}/thumbnails/${currentEvent.thumbnailUri}` : `${currentServer.protocol}://${currentServer.ip}:${currentServer.port}/${CIVETWEB_ENTRYPOINT}/thumbnails/${currentEvent.thumbnailUri}`;
        }
        //CHI è STATO IL MALEDETTO CHE HA DECISO DI METTERE "none" quando non c'è la thumb invece di mettere qualcosa nullable?
        if(currentEvent.thumbnailUri === "none") {
          currentEvent.thumbnailUri = "";
        }
        //currentEvent.thumbnailUri = `${currentServer.protocol}://${currentServer.ip}:${currentServer.port}/${CIVETWEB_ENTRYPOINT}/thumbnails/${currentEvent.thumbnailUri}`;

        let preTime = currentEvent.startTime;
        let postTime = currentEvent.endTime;

        if (currentEvent.startTime == currentEvent.endTime) {
          // manipolazione orario nel caso vi sia un evento con durata = 0
          preTime = addSecondsToServerDate(currentEvent.startTime, -PRE_EVENT_TIME);
          postTime = addSecondsToServerDate(currentEvent.endTime, POST_EVENT_TIME);

        }
                

        if (currentEvent?.eventData) {

          try {
            currentEvent.eventData = JSON.parse(currentEvent.eventData);         
            //MOCK
            //currentEvent.eventData?.transCam = '10';
            //currentEvent.eventData?.transCam = 'V03220059_4';
            //currentEvent.eventData?.relatedCameras = ['3e655da4a886561dcdc0cfc167170dc_1_10'];
          } catch (e) {
            // impossibile convertire dato
            logger(error, "events", "Error trying parsing additional data for event " + eventArtecoEventId);
          }

        }




        currentEvent.startTime = preTime;
        currentEvent.endTime = postTime;        

        listOfthumbnailsOfEvents.push({
          artecoEventId: eventArtecoEventId,
          eventId: currentEvent.id,
          artecoId: currentEvent.artecoId,
          fullPath: currentEvent.thumbnailUri,
          startTime: currentEvent.startTime,
          endTime: currentEvent.endTime,
          eventTime: currentEvent.eventTime,
          color: currentEvent.color,
          params: currentEvent.params
        });

        if(currentEvent.thumbnailUri &&currentEvent.thumbnailUri.endsWith(".jpg") ) {          
          if(!newDictionary[currentEvent.artecoId]) {
            newDictionary[currentEvent.artecoId] = {};
          }
          const roundedEventTime = roundToNearestThreeOrZero(currentEvent.eventTime);
  
          newDictionary[currentEvent.artecoId][roundedEventTime] = {
            thumbnailUri: currentEvent.thumbnailUri,
            eventData: `${currentServerCodeName}_${currentEvent.deviceType}_${currentEvent.id}`
          };
        }


      }


      let listOfArtecoId = new Set(payloadSessions.map(session => session.artecoId));
      let recordingsData = {};
      let peripheralsEvents = payloadEvents.filter(event => filterFunction(event, true));
      let cleanEvents = payloadEvents.filter(event => filterFunction(event, false));
      let recThumbs = action.payload.thumbsData;


      for (const artecoId of listOfArtecoId) {

        let sessions = payloadSessions.filter(session => session.artecoId == artecoId);
        let events = payloadEvents.filter(event => event.artecoId == artecoId);

        let mainRec = {
          startTime: sessions[0].startTime,
          endTime: sessions[sessions.length - 1].endTime,
          edgeRecInfo: sessions[0]?.addInfo != undefined ? sessions[0]?.addInfo : null,
        }

        let missingRec = [];
        let previousEndTime = "00000000000000";
        let previousEyeId = null;

        for (const session of sessions) {

          if (previousEndTime === "00000000000000") {
            previousEndTime = session.startTime;
          }

          if (previousEndTime != session.startTime) {

            missingRec.push(
              {
                previousEyeId,
                nextEyeId: session.eyeId,
                startMissingRec: previousEndTime,
                endMissingRec: session.startTime,
              }
            )

          }

          previousEndTime = session.endTime;
          previousEyeId = session.eyeId;
        }

        recordingsData[artecoId] = {
          mainRec,
          missingRec,
          events,
        };

      }

      const newRec = {
        ...state.recordings
      }

      const serverInfo = {
        ...currentServer,
        mediaSecret: currentSearch.server.mediaSecret
      }

      newRec[currentServerCodeName] = {
        totalEvents: action.payload.info?.totalEvents,
        totalSessions: action.payload.info?.totalSessions,
        serverInfo,
        recordingsData,
        peripheralsEvents: peripheralsEvents,
        cleanEvents: cleanEvents,
        recThumbs: recThumbs
      };


      delete state.errors[currentServerCodeName];

      return {
        ...state,
        pendingRecording: state.pendingRecording - 1, //????
        recordings: newRec,
        searchParams: {
          start: currentSearch.recSearchFormData?.start,
          end: currentSearch.recSearchFormData?.end,
          url: `from=${currentSearch.startTime}&to=${currentSearch.endTime}`
        },
        eventsThumbnails: state.eventsThumbnails.concat(listOfthumbnailsOfEvents),
        thumbsDictionary: newDictionary
      }
    case FETCH_RECORDING_ERROR:

      logger(info, loggerScope, "FETCH_RECORDING_SUCCESS per server" + action.payload.serverId + "serverName " + action.payload.serverName)

      let listOfErrors = { ...state.errors };

      delete state.recordings[action.payload.serverCodename];

      listOfErrors[action.payload.serverCodename] = {
        message: action.payload.code === 200 ? 'SERVER_OBSOLETE' : action.payload.errors, //add fix versione Demis // creo errori
        code: action.payload.code,
        serverId: action.payload.serverId,
        serverIp: action.payload.serverIp,
        serverName: action.payload.serverName,
        codeName: action.payload.codeName,
      }

      return {
        ...state,
        pendingRecording: state.pendingRecording - 1, //????
        errors: listOfErrors
      }

    case SERVER_DISCONNECT:
      const cleanRecs = {
        ...state.recordings
      }

      delete cleanRecs[action.payload.codeName];

      return {
        ...state,
        recordings: cleanRecs
      }
    case SERVER_DISCONNECT_ALL:
      return {
        ...state,
        recordings: {}
      }      
    case TIMELINE_MARKER:

      return {
        ...state,
        timelineMarker: action.payload,
      }
    case SET_EXPORT_INTERVAL:

      return {
        ...state,
        exportStart: action.exportInterval.start ? action.exportInterval.start : state.exportStart,
        exportEnd: action.exportInterval.end ? action.exportInterval.end : state.exportEnd,

      }

    case RESET_EXPORT_INFO:

      return {
        ...state,
        exportStart: null,
        exportEnd: null,
      }

    case SET_SYNC_MODE:
      return {
        ...state,
        syncMode: action.syncMode
      }


    case SET_TIMELINE_MODE:
      return {
        ...state,
        timelineMode: action.payload,
      }

    case SET_EDGE_MODE:
      return {
        ...state,
        edgeMode: action.payload,
      }

    case TOGGLE_TIMELINE_VISIBILITY:
      const shouldShow = action.payload == 'auto' ? !state.timelineVisible : (action.payload == 'show' ? true : false)
      return {
        ...state,
        timelineVisible: shouldShow,
      }

    default:
      return state;
  }
}



export const getRecordings = state => state.recordings;
export const getPendingRecording = state => state.recordings.pendingRecording;
export const getRecordingsError = state => state.recordings.errors;
export const getEvtThumbnails = (state) => {

  return state.recordings.eventsThumbnails;

}
export const getRecordingsSearchParams = state => state.recordings.searchParams;
export const getTimeLineMarker = state => state.recordings.timelineMarker;

export const getExportInterval = state => {

  const interval = {
    start: state.recordings.exportStart,
    end: state.recordings.exportEnd,
  };

  return interval
}

export const gotSomeRecs = (state) => {

  return Object.keys(state.recordings.recordings).length > 0

}

function orderByLastUpdate(a, b) {
  if (a.lastUpdateTime > b.lastUpdateTime) {
    return -1;
  }
  if (a.lastUpdateTime < b.lastUpdateTime) {
    return 1;
  }
  return 0;
}


export const getListOfEventsFromRec = (state) => {

  // recordings[server] ->recordingsData[device] -> events

  // recordings -
  //  |-> serverId 
  //    | -> recordingsData
  //      | -> deviceId
  //         | -> events 

  let eventsData = [];

  Object.values(state.recordings.recordings).forEach(function (serverItem) {

    Object.values(serverItem.recordingsData).forEach(function (deviceItem) {

      eventsData = eventsData.concat(deviceItem.events)

    });

  });

  //order sort always  by eventtime newest first
  const sortByEventTime = true , sortAC = false;
  eventsData.sort((a,b)=> sortEventsByEventTimeAC(a,b,sortByEventTime,sortAC));

  return eventsData;
}

export const getListOfEventsFromRecPlusPeripherals = (state) => {

  // recordings[server] ->recordingsData[device] -> events

  // recordings -
  //  |-> serverId 
  //    | -> recordingsData
  //      | -> deviceId
  //         | -> events 
  //    | -> peripheralsEvents

  let eventsData = [];

  Object.values(state.recordings.recordings).forEach(function (serverItem) {

    Object.values(serverItem.recordingsData).forEach(function (deviceItem) {

      eventsData = eventsData.concat(deviceItem.events)

    });

    eventsData = eventsData.concat(serverItem.peripheralsEvents);

  });

   //eventsData.sort(orderByLastUpdate);
   //order by eventtime newest first
   const sortByEventTime = true , sortAC = false;
   eventsData.sort((a,b)=> sortEventsByEventTimeAC(a,b,sortByEventTime,sortAC));

  return eventsData;
}

export const getEventListNoSessionConstraints = (state) => {
  let eventsData = [];
  let limited = false;

  Object.values(state?.recordings?.recordings)?.forEach(function (serverItem) {
    if (serverItem?.cleanEvents?.length >= 5000) {
      if(!limited) {
        limited = [serverItem.serverInfo.codeName];  
      } else {
        limited = [...limited, serverItem.serverInfo.codeName];
      }
    }

    eventsData = eventsData.concat(serverItem?.cleanEvents);
    eventsData = eventsData.concat(serverItem?.peripheralsEvents);
  });

  const sortByEventTime = areEventsSortByEventTime(state) , sortAC =  areEventsSortByAc(state);
  eventsData.sort((a,b) => sortEventsByEventTimeAC(a,b,sortByEventTime,sortAC));
  const isCredentinalEvents = eventsData.filter(event => {
    const server = getServerByCodeName(state, event.serverCodename);
    return isCredentialEnabled(Events, server) && event;
  });

  return {
    events: isCredentinalEvents,
    limited: limited
  };
}

export const getPlaylistSyncMode = state => state.recordings.syncMode;
// export const getMyNextThumb = (state, time, artecoId, serverCodeName) => {
//   if (!artecoId || !serverCodeName || !state.recordings.recordings[serverCodeName] || !state.recordings.recordings[serverCodeName].cleanEvents || !state.recordings.recordings[serverCodeName].recThumbs) return null;

//   const theseEvents = state.recordings.recordings[serverCodeName].cleanEvents.filter(event => event.artecoId === artecoId);  
//   const theseThumbs = state.recordings.recordings[serverCodeName].recThumbs.filter(thumb => thumb.artecoId === artecoId);

//   if (theseEvents.length === 0 && theseThumbs.length === 0) {
//     return null;
//   }

//   const validPix = theseEvents.filter(event => (serverStringMajorEqual(event.endTime,time)));

//   const mostRecentEvent = validPix[0];
  
//   const validThumbs = theseThumbs.filter(thumb => (serverStringMajorEqual(thumb.timestamp, time)));

//   const mostRecentThumb = validThumbs[0];

//   let bestFit = '';
  
//   if(mostRecentEvent && mostRecentThumb) {
//     bestFit = mostRecentEvent && serverStringMinor(mostRecentEvent.endTime <= mostRecentThumb.timestamp) ? 'event' : 'thumb';
//   } else {
//     bestFit = mostRecentThumb ? 'thumb' : 'event'
//   }
  
//   let ThumbsData = {
//     type: bestFit
//   };
//   if(bestFit === 'event') {
//     ThumbsData =  {
//       ...ThumbsData,
//       thumbUri: mostRecentEvent && mostRecentEvent.thumbnailWidth ? mostRecentEvent.thumbnailUri : "",
//       thumbTime: (mostRecentEvent && mostRecentEvent.endTime) || null,      
//     }
//   } else {
//     ThumbsData =  {
//       ...ThumbsData,
//       thumbUri: mostRecentThumb ? mostRecentThumb.thumbnailUri : "",
//       thumbTime: (mostRecentThumb && mostRecentThumb.timestamp) || null,      
//     }
//   }

//   return ThumbsData;
// }

export const sortThumbsDictionary = (dictionary) => {
  const sortedDictionary = {};

  for (let key in dictionary) {
    const entries = Object.entries(dictionary[key]);
    const sortedEntries = entries.sort((a, b) => b[0] - a[0]); // per un ordinamento decrescente
    sortedDictionary[key] = Object.fromEntries(sortedEntries);
  }

  return sortedDictionary;
}

export function getMinAndMaxTimestamps(dictionary, includedCameras) {
  let minTimestamp = null;
  let maxTimestamp = null;

  includedCameras.forEach(cameraId => {
    if(dictionary[cameraId]) {
      const timestampsArray = Object.keys(dictionary[cameraId]);
  
      const cameraMinTimestamp = Math.min(...timestampsArray);
      const cameraMaxTimestamp = Math.max(...timestampsArray);
  
      if (!minTimestamp || cameraMinTimestamp < minTimestamp) {
        minTimestamp = cameraMinTimestamp;
      }
  
      if (!maxTimestamp || cameraMaxTimestamp > maxTimestamp) {
        maxTimestamp = cameraMaxTimestamp;
      }
    }
  });

  return {minTimestamp, maxTimestamp};
}

export function getClosestEventTimestamp(dictionary, includedCameras, currentServerString, direction) {
  let closestTimestamp = null;

  includedCameras.forEach(cameraId => {
    const cameraTimestamps = dictionary[cameraId];

    if (cameraTimestamps) {
      const timestampsArray = Object.keys(cameraTimestamps);
      
      const cameraClosestTimestamp = timestampsArray.reduce((closest, current) => {
        if (direction === 'next') {
          return (current > currentServerString && (!closest || current < closest)) ? current : closest;
        } else { // Assume 'prev' if not 'next'
          return (current < currentServerString && (!closest || current > closest)) ? current : closest;
        }
      }, null);

      if (cameraClosestTimestamp && (!closestTimestamp || (direction === 'next' ? cameraClosestTimestamp < closestTimestamp : cameraClosestTimestamp > closestTimestamp))) {
        closestTimestamp = cameraClosestTimestamp;
      }
    }
  });

  return closestTimestamp;
}



export const getEventThumbsDictionary = (state) => {  
  return state.recordings.thumbsDictionary || {};
}


export const getMyNextThumb = (state, time, artecoId, serverCodeName) => {
  const currentServer = getServerByCodeName(state, serverCodeName);
  if(!currentServer) return '';

  if(!currentServer.capabilities.thumbnailTrack) return '';

  const localTime = convertUTCToLocal(time, currentServer.timezone);

  const deviceData = artecoId.split("_");
  const deviceId = deviceData[deviceData.length - 1];

  const nextThumbHour = localTime.slice(0, -4)+"0000_JPEG";
  const nextThumbTime = roundToNearestThreeOrZero(localTime);

  const nextEventTime = roundToNearestThreeOrZero(time);
  const eventThumb = state.recordings.thumbsDictionary[artecoId] && state.recordings.thumbsDictionary[artecoId][nextEventTime];

  let type = 'thumb';
  let nextThumbnailUri = '';
  if(eventThumb && eventThumb.thumbnailUri) {
    type='event';
    nextThumbnailUri = eventThumb.thumbnailUri;
  } else {
    nextThumbnailUri = currentServer.nodeServer && `${currentServer.protocol}://${currentServer.ip}:${currentServer.port}/thumbnails/${deviceId.toString().padStart(2,'0')}/Tracks/${nextThumbHour}/${nextThumbTime}.jpg`;
  }

  const thumbData = {
    thumbTime: time,
    thumbUri: nextThumbnailUri,
    thumbEvent: eventThumb && eventThumb.eventData,
    type: type
  }

  return thumbData

}

export const getCLeanEventByArtecoId = (state, artecoEventId) => {
  const params = artecoEventId.split("_");
  const serverCodeName = params[0];
  const eventId = parseInt(params[params.length - 1]);

  const serverEvents = state.recordings.recordings[serverCodeName];

  return serverEvents && serverEvents.cleanEvents && serverEvents.cleanEvents.find(event => event.id === eventId);  
}

export const getTimelineMode = state => state.recordings.timelineMode;
export const getTimelineVisibility = state => state.recordings.timelineVisible;
export const getEdgeMode = state => state.recordings.edgeMode;
