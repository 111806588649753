import React, { Component } from 'react';
import { connect } from "react-redux";
import fetchPlaylist from '../../actions/fetchPlaylists';
import { getPlaylistById, getPlaylistError, getPlaylistsByArtecoId } from "../../reducers/playlistsReducer";
import { getParentServerByArtecoId, getDeviceByArtecoId, getMediaUri, getChannelRunningStatus, isCredentialEnabled } from "../../reducers/serversReducer";
import { setChannelMode } from "../../actions/layoutActions";
import { forceLogin } from '../../actions/serverActions';
import { withTranslation } from 'react-i18next';
import { setTimelineMode } from '../../actions/recordingsActions';

import ClapprPlayer from './ClapprPlayer';
import PlayerContextMenu from '../dashboard/Stage/PlayerContextMenu';
import PlayerWebRTC_Styled from './PlayerWebRTC_Styled';
import { info, logger } from '../../helpers/logger';
import { addInterval, serverTimeDiff, subtractInterval } from '../../helpers/timeHelpers';
import { controlSome, seekSome } from './ArtecoPlayerMethods'

import { resetPlaylistsErrorByArtecoId, updatePlaylistStatus } from '../../actions/playlistActions';

import PunctualPlayer from './PunctualPlayer';
import ImageLazyLoad from '../CustomLazyLoadImages/CustomLazyLoadImages';
import { isIOS, isTablet } from 'react-device-detect';
import { getEdgeMode, getTimeLineMarker } from '../../reducers/recordingsReducer';
import { getChannelMode, getChannelPrivacyShown } from '../../reducers/layoutsReducer';
import PrivacyDisplay from './PrivacyDisplay';
import { CamerasPrivacyBlur } from '../../helpers/serverCredentials';
const loggerScope = "ArtecoPlayer";

class ArtecoPlayer extends Component {

  /*                                                
      Questo componente gestisce video HLS, WebRTC, visualizzazione delle playlist, fetch delle playlist e visualizzazione delle snapshot degli eventi (mediaUri)
      artecoId={String} // arteco id of the element
      key={String} // key of the element
      from={YYYYMMDDhhmmss} // start of stream (if rec)
      to={YYYYMMDDhhmmss} // end if stream (if rec)
      punctualTime={YYYYMMDDhhmmss} // tempo che si desidera visualizzare (il componenete si autogestisce la creazione della playlist)
      autoFetchOnEnd={Bool} // loop fetch infinite (solo con startTime)
      timelineMode={String} // play/pausa/stop
      className={String} // additional class name, use it with caution !!!!
      isPopup={Bool} // ispopup mode
      useWebRTC={Bool} // force webrtc for live
      allowBackToLive={Bool} // consendire di tornare al live oppure no
      allowOnlyPIP={Bool} // mostra SOLO il tasto picture in picture nascondendo l'instant player e relativi tasti
      hideContextMenu={Bool} // nasconde menu contestuale
      disableZoom={Bool} // disabilita lo zoom
      eventImage={Uri} // immagine evento (funziona solo se timelinemode == stop)
  */

  constructor(props) {
    super(props);

    this.state = {
      forced: false, // forzatura del login in caso di errore di sessione
      HLSliveNotAvailable: false, // errori live del player hls
      currentPlaylist: undefined, // playlist attuale da caricare
      endVideoRequested: false, // nuova playlist caricata ?
      ignoreNewPlaylists: false, // fase di ignoring delle nuove playlist (intervallo tra secondsBeforeRequest e fine playlist)
      invalidPlaylistDuration: false, // playlist non valida (durata inferiore all'atteso)
      waitingForNewPlayAttempt: false, // in attesa di una playlist dopo averne shrinkata una a casausa di una 'invalidPlaylistDuration'
      invalidPlaylistDurationCounter: 0, // tentativi in corso prima di dichiarare la morte della riproduzione video 
    }

    // millisecondi -> 2,30 minuti per mantenere retrocompatibilità con la versione di produzione
    this.playlistFetchInterval = 300000;
    // secondi prima dei quali deve essere effettuata la prefetch della playlist successiva
    this.secondsBeforeRequest = 60;
    // tentativi massimi prima di dichiarare la morte della riproduzione video
    this.maxRetryInvalidPlaylistAttempt = 2;
    this.nodeRef = React.createRef();

    this.isIpad = isIOS && isTablet;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.nodeRef.current && !this.state.dblclickListener) {
      this.setState({
        dblclickListener: true
      }, () => {
        this.nodeRef?.current && this.nodeRef?.current.addEventListener('dblclick', function (e) {
          e.preventDefault();
          e.stopPropagation()
        }, true);
      })
    }
    const { playlistError, forceLogin, server, from, to, useWebRTC, punctualTime, timelineMode, playlists, device ,isPrivacyPopUpOn} = this.props;
    const { forced, ignoreNewPlaylists } = this.state;

    //auto login in caso di errore di login
    if (!forced && playlistError === 'NO_USER_LOGGED_IN_SESSION_IS_NOT_VALID') {
      this.setState({
        forced: true
      }, () => {
        forceLogin({ _id: server._id });
      })
    }

    if (prevProps.playlistError != playlistError) {

      if (playlistError == undefined) {
        this.setState({
          forced: false,
        })
      }
    }

    if (this.playlistForThisDevChanges(prevProps.playlists, playlists) && !ignoreNewPlaylists) {


      // richiedo una nuova playlist SOLO se non sono nella fase di 'ignoring' ovvero durante il play continuativo
      this.kindlyRequestPlaylist();
    }


    if (
      (prevProps.from !== from) ||
      (prevProps.to !== to) ||
      (prevProps.server?.sessionId !== server?.sessionId) ||
      (prevProps.server?.access_token !== server?.access_token) ||
      (punctualTime !== prevProps.punctualTime) ||
      (prevProps.timelineMode !== timelineMode && (prevProps.timelineMode == 'stop' && timelineMode == 'play'))
      // fai la richiesta solo se è un passaggio da stop a play
    ) {
      this.setState({
        ignoreNewPlaylists: false,
        invalidPlaylistDuration: false,
        waitingForNewPlayAttempt: false,
        invalidPlaylistDurationCounter: 0,
      }, () => {
        this.kindlyRequestPlaylist();
      })
    }

    if (
      (prevProps.useWebRTC !== useWebRTC && useWebRTC) ||
      (prevProps.server?.access_token !== server?.access_token && !useWebRTC)
    ) {
      this.resetLiveErrors();
    }

  }

  compareArrays = (arr1, arr2) => {
    if (arr1.length != arr2.length) {
      return false;
    }
    else {
      let result = false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] != arr2[i]) {
          return false;
        }
        else {
          result = true;
        }
      }
      return result;
    }
  }


  playlistsChanged(oldPlaylists, newPlaylists) {

    if (oldPlaylists.length == 0 || newPlaylists.length == 0) {
      return false; // controllo lunghezza
    }

    if (oldPlaylists.length != newPlaylists.length) {
      return true; // controllo differenza playlist
    }


    var somePlaylistChangedTo = oldPlaylists.map((el, index) => { // controllo se è cambiato il to
      return el.to !== newPlaylists[index].to ? 1 : 0
    })
    var hasDiff = somePlaylistChangedTo.length !== 0 ? somePlaylistChangedTo.reduce((a, b) => a + b) : null;

    return hasDiff;

  }


  playlistForThisDevChanges(oldPlaylists, newPlaylists) {

    const { autoFetchOnEnd } = this.props;

    if (!autoFetchOnEnd) {
      return;
    }

    if (oldPlaylists.length == 0 || newPlaylists.length == 0) {
      return false; // controllo lunghezza
    }

    if (oldPlaylists.length != newPlaylists.length) {
      return true; // controllo differenza playlist
    }

    var somePlaylistChangedTo = oldPlaylists.map((el, index) => { // controllo se è cambiato il to
      return el.to !== newPlaylists[index].to ? 1 : 0
    })
    var hasDiff = somePlaylistChangedTo.length !== 0 ? somePlaylistChangedTo.reduce((a, b) => a + b) : null;

    return hasDiff;
  }


  gotMaxPlaylistAttempt = () => {

    // raggiunto il limite di tentativi massimi
    const { invalidPlaylistDurationCounter } = this.state;
    return invalidPlaylistDurationCounter > this.maxRetryInvalidPlaylistAttempt;

  }

  handleLiveErrors = () => {
    // ricevuto un errore live (vod arrivato mentre sono in live)
    const { HLSliveNotAvailable } = this.state;
    if (!HLSliveNotAvailable) {
      this.setState({
        HLSliveNotAvailable: true
      })
    }
  }

  resetLiveErrors = () => {
    // pulisco gli errori
    const { HLSliveNotAvailable } = this.state;
    if (HLSliveNotAvailable) {
      this.setState({
        HLSliveNotAvailable: false
      })
    }
  }

  gotAnyHLSLiveErrors() {
    const { HLSliveNotAvailable } = this.state;
    return HLSliveNotAvailable;
  }

  shouldComponentRender() {
    const { artecoId, device, isPopup } = this.props;
    const shouldRender = (
      (artecoId != undefined) &&
      (isPopup != undefined) &&
      (device != undefined));

    return shouldRender;
  }

  isWebButDoesntHaveUrl() {
    const { useWebRTC, rtspUrl } = this.props;

    if (useWebRTC == true && rtspUrl == undefined) {
      return false;
    }

    return true;
  }

  shouldComponentUpdate(nextProps, nextState) {


    const { artecoId, key, from, to, playerClass, isPopup, useWebRTC, playlistId, server, device, playlist, playlistError, liveMedia, recMedia,
      hideContextMenu, disableZoom, playlists, punctualTime, autoFetchOnEnd, timelineMode, mediaUri, eventImage, selectedTime,isPrivacyPopUpOn,channelPrivacyShown, isChRunning} = this.props;

    const { HLSliveNotAvailable, currentPlaylist, endVideoRequested, ignoreNewPlaylists } = this.state;

    if (
      // props
      nextProps.artecoId !== artecoId ||
      nextProps.key !== key ||
      nextProps.from !== from ||
      nextProps.to !== to ||
      nextProps.playerClass !== playerClass ||
      nextProps.isPopup !== isPopup ||
      nextProps.useWebRTC !== useWebRTC ||
      nextProps.playlistId !== playlistId ||
      nextProps.server?.access_token !== server?.access_token ||
      nextProps.server?._id !== server?._id ||
      nextProps.server?.capabilities?.canManageCameras !== server?.capabilities?.canManageCameras ||
      nextProps.device?.enabled !== device?.enabled ||
      nextProps.device?.running !== device?.running ||
      nextProps.device?.artecoId !== device?.artecoId ||
      nextProps.isChRunning !== isChRunning ||
      nextProps.playlist?.metadataHistory !== playlist?.metadataHistory ||
      nextProps.playlist?.onvifMetadataHistory !== playlist?.onvifMetadataHistory ||
      nextProps.playlist?.to !== playlist?.to ||
      nextProps.playlist?.from !== playlist?.from ||
      this.playlistsChanged(nextProps.playlists, playlists) ||
      nextProps.playlistError !== playlistError ||
      nextProps.liveMedia !== liveMedia ||
      nextProps.recMedia !== recMedia ||
      nextProps.hideContextMenu !== hideContextMenu ||
      nextProps.disableZoom !== disableZoom ||
      nextProps.punctualTime !== punctualTime ||
      nextProps.timelineMode !== timelineMode ||
      nextProps.autoFetchOnEnd !== autoFetchOnEnd ||
      nextProps.mediaUri !== mediaUri ||
      nextProps.eventImage !== eventImage ||
      nextProps. isPrivacyPopUpOn !== isPrivacyPopUpOn ||
      nextProps.channelPrivacyShown !== channelPrivacyShown ||

      // states
      nextState.HLSliveNotAvailable !== HLSliveNotAvailable ||
      nextState.currentPlaylist?.to !== currentPlaylist?.to ||
      nextState.endVideoRequested !== endVideoRequested ||
      nextState.ignoreNewPlaylists !== ignoreNewPlaylists) {
      return true;
    }
    return false;
  }

  giveMeThePlaylist = () => {

    // ottengo la playlist tra quelle disponibili nello state
    const { punctualTime, artecoId, device, playlists, timelineMode, setTimelineMode } = this.props;
    const { endVideoRequested, currentPlaylist, invalidPlaylistDuration, waitingForNewPlayAttempt } = this.state;

    if (
      device == undefined ||
      artecoId == undefined) {
      return; // dati in ingresso non validi
    }

    /*
    Logica di funzionamento :
    Prima fetch:
    ......|**playlists_attuale**|......
    Quando la playlis tattuale arriva alla soglia stabilita nel (this.progressTheshold) allora viene effettuata una nuova fetch della durata di (this.playlistFetchInterval)
    ......|**playlists_attuale**|**playlistFetchInterval**|......
    Questa operazione si ripete all'infinito fino alla fine del registrato
    */

    let timeToCheckint = parseInt(punctualTime); // di default prendo il tempo che arriva come orario puntuale

    if (endVideoRequested) {
      timeToCheckint = parseInt(currentPlaylist.to); // prendo il tempo finale come inizio nuova ricerca
      this.setState({
        endVideoRequested: false,
      })
    }

    if (invalidPlaylistDuration) {
      // caso playist non valida
      this.setState({
        invalidPlaylistDuration: false,
        waitingForNewPlayAttempt: true,
      })
      timeToCheckint = parseInt(currentPlaylist.to); // prendo il tempo finale come inizio nuova ricerca
    } else {
      if (waitingForNewPlayAttempt) {
        // arrivata playlist 'buona' dopo aver shrincato quella non valida
        this.setState({
          waitingForNewPlayAttempt: false,
        })
        timeToCheckint = parseInt(currentPlaylist.to); // prendo il tempo finale come inizio nuova ricerca
      }
    }

    if (this.gotMaxPlaylistAttempt()) {
      return;
    }

    // ottengo la playlist dato un certo orario (se torna undefined, nessuna playlist presente)

    let validPlaylist = undefined;
    playlists.forEach(playlist => {
      if (timeToCheckint >= parseInt(playlist.from) && timeToCheckint < parseInt(playlist.to)) {
        validPlaylist = playlist;
      }
    })

    if (validPlaylist != undefined) {
      this.setState({
        currentPlaylist: validPlaylist,
      }, () => {
        const diffInSeconds = serverTimeDiff(validPlaylist.from, timeToCheckint, 'seconds');
        seekSome([artecoId], diffInSeconds);
      })

    } else {
      controlSome([device.artecoId], 'pause')
      this.fetchMyPlaylist();
    }

  }

  requestNextPlaylist = () => {

    // caricamento della playlist successiva a quella in corso

    const { currentPlaylist } = this.state;
    const { autoFetchOnEnd } = this.props;

    if (!autoFetchOnEnd || currentPlaylist == undefined) {
      return;
    }

    this.setState({
      ignoreNewPlaylists: true,
    })

    this.fetchMyPlaylist(currentPlaylist.to) // fetch playlist che come inizio ha la fine della corrente + delta del tempo

  }

  reachedEndOfVideo = (time, currentPlaylistDuationSeconds) => {
    // richiesta play nuova playlist successiva
    const { endVideoRequested } = this.state;
    const { autoFetchOnEnd, device, updatePlaylistStatus } = this.props;


    if (!autoFetchOnEnd || time == undefined) {
      return;
    }

    const self = this;

    if (!endVideoRequested) { // non sono nel 'mezzo di una richiesta

      this.setState({
        endVideoRequested: true, // nuova richiesta
        ignoreNewPlaylists: false,
      }, () => {


        if (this.playlistInvalidDuration(currentPlaylistDuationSeconds)) {

          // devo prendere la playlist attuale e semplicemente modificare l'orario di inizio e fine
          // una volta modificato l'orario di inizio e fine devo poi mandare
          // come props il dato di rfiertimento assoliuto della playulist come se fopssem l'informazione di rientero
          // ok facciamo !


          //const newPlaylistId = `${self.props.device.artecoId}_${self.state.currentPlaylist.from}_${time}`; // nuova playlistId

          this.setState({
            invalidPlaylistDuration: true,
            invalidPlaylistDurationCounter: self.state.invalidPlaylistDurationCounter + 1, // incremento il numero di errori
            currentPlaylist: {
              ...self.state.currentPlaylist,
              to: time,
              playlistId: self.state.currentPlaylist.playlistId,
            }
          }, () => {

            self.props.updatePlaylistStatus({
              artecoId: self.props.device.artecoId,
              playlistId: self.state.currentPlaylist.playlistId,
              to: time,
            });

          })

        } else {

          this.setState({
            invalidPlaylistDuration: false,
            waitingForNewPlayAttempt: false,
            invalidPlaylistDurationCounter: 0,
          }, () => {
            this.giveMeThePlaylist();

          })

        }

      })
    }

  }

  fetchMyPlaylist = (startTime, endTime) => {
    // richiesta generazione playlist
    const { fetchPlaylist, punctualTime, server, device, artecoId } = this.props;
    const { invalidPlaylistDuration, currentPlaylist } = this.state;

    if (device == undefined ||
      artecoId == undefined) {
      return; // non va bene
    }

    // preparo le variabili
    let startEdited = undefined;
    let startWithBeforeTime = undefined;
    let start = undefined;
    let end = undefined;

    // playlist con solo tempo

    if (punctualTime) {
      // caso 'standard' di una richiesta di una playlist
      startWithBeforeTime = subtractInterval(punctualTime, this.playlistFetchInterval / 2);
      start = startWithBeforeTime;
      end = addInterval(punctualTime, this.playlistFetchInterval / 2);
    }

    if (invalidPlaylistDuration) {
      // caso playist non valida
      // this.setState({
      //   invalidPlaylistDuration: false,
      // })

      start = currentPlaylist.to;
      end = addInterval(currentPlaylist.to, this.playlistFetchInterval);
    }

    // Playlist 'classica' from e to

    if (startTime) {
      // fallback nel caso in cui la fetch è richiesta dalla fine di un reigstrato
      start = startTime;
      end = addInterval(startTime, (this.playlistFetchInterval));
    }

    if (endTime) {
      // playlist 'classica' dove ho già definitio from e to
      end = endTime;
    }

    if (start == undefined || end == undefined) {
      return;
    }

    const playlistReq = {
      ...device,
      deviceId: device.id,
      serverCodename: server.codeName,
      serverId: server._id,
      serverIp: server.ip,
      serverPort: server.port,
      serverProtocol: server.protocol,
      serverRealname: server.name,
      sessionId: server.sessionId,
      access_token: server.access_token,
      artecoId: device.artecoId,
      serverIsLocal: server.isLocal,
      mediaSecret: server.MediaSecret,
      server: server,
      startTime: start,
      endTime: end,
      dvr: 1,
      hasMeta: device.metadataEnabled
    }

    fetchPlaylist(playlistReq);
  }

  playlistInvalidDuration = (playlistDuration) => {

    const { device } = this.props;
    // darata playlist inferiore a quella prevista
    if (Math.floor(playlistDuration) < this.playlistFetchInterval) {
      // ops, la playlist è finita prima di quanto mi aspettassi
      // alert("Playlist terminata prima del previsto !!")
      logger(info, loggerScope, "Bug del server, la playlist m3u8 è arrivata dal server con una durata minore a quella richiesta !! probabilmente qualche frame è marcio ! device = " + device.descr)
      return true;
    }
    return false;
  }

  componentDidMount() {
    // serve nel caso in cui i props siano già stati forniti all'avvio
    this.kindlyRequestPlaylist();
  }

  kindlyRequestPlaylist = () => {

    // questo è il punto di arrivo per ogni richiesta di una nuova playlists, lui si occupa di chiedere una playlist from-to o solo con un tempo specifico

    const { from, to, punctualTime } = this.props;

    const classicPlaylistRequest = (from != undefined) && (to != undefined);  // classica richiesta di una playlist con start/stop (come sei old style)
    const newPlaylistRequest = !classicPlaylistRequest && punctualTime // nuova richiesta con solo il tempo che mi interessa

    if (classicPlaylistRequest) {
      // playlist FROM e TO
      this.fetchMyPlaylist(from, to);
    }

    if (newPlaylistRequest) {
      // playlist con orario ESATTO
      this.giveMeThePlaylist();
    }

  }

  switchToLive = () => {
    const { artecoId, setChannelMode, resetPlaylistsErrorByArtecoId } = this.props;

    this.setState({
      invalidPlaylistDuration: false,
      invalidPlaylistDurationCounter: 0,
    })

    const mode = {
      mode: `live`,
      from: undefined,
      to: undefined
    }
    resetPlaylistsErrorByArtecoId(artecoId);
    setChannelMode(mode, artecoId);
  }

  gotPlaylist = () => {
    const { playlist } = this.props;
    return playlist;
  }

  gotPlaylistError = () => {
    const { playlistError } = this.props;
    return playlistError;
  }

  renderOfflineMessage = () => {
    const{t} = this.props;
    return (
      <span className='arteco-player'>

        <div className={`clappr offline-camera ${this.props.className}`}>
          <PlayerContextMenu 
          artecoId={this.props.artecoId} 
          isPlayback={this.props.isPlayback} 
          />
          <div className="errors">
            <p>{t('PLAY_ERROR_SERVER_OFFLINE')}</p>
          </div>
        </div>
      </span>
    )
  }

  render() {

    const { onvifMetadataHistory, metadataHistory, artecoId, device, key, from, to, playerClass, isPopup, timelineMode, useWebRTC, t, playlistError, playlistId, playlist, allowBackToLive, allowOnlyPIP, recMedia, liveMedia, hideContextMenu, disableZoom, eventImage, server, forcePlayBackMode, autoFetchOnEnd, index, rtspUrl, isPlayback, isEdgeMode, fallbackImage, hidePrivacy, origin, marker,channelMode, isChRunning,isPrivacyPopUpOn } = this.props
    const { currentPlaylist } = this.state;

    
    if (!this.shouldComponentRender()) {
      return <>Nothing to render</>
    }

    // if (!this.isWebButDoesntHaveUrl()) {
    //   return <>No url</>
    // }

    if (server.offline) {
      return this.renderOfflineMessage();
    }

    const cleanedClass = playerClass ? playerClass : '';
    const notStopOrUndefined = (timelineMode != 'stop' || timelineMode == undefined); // non in modalità timline oppure in stop


    let cantPlayMessage = '';
    const isRunning = device && (isChRunning !==null ? isChRunning === 1 : device.running === 1);
    const showRecForOfflineCamera = (forcePlayBackMode || channelMode.mode !== 'live') ? true : false;
    const isCameraDisabled = (device?.enabled !== 1 && !isRunning && server?.capabilities?.canManageCameras === 1) ? true : false;
    const isCameraOffline = (device?.enabled !== 0 && !isRunning && server?.capabilities?.canManageCameras === 1) ? true : false;


    if(!showRecForOfflineCamera){

      if((!useWebRTC && this.gotAnyHLSLiveErrors() && server?.capabilities?.canManageCameras !== 1) ){
  
        cantPlayMessage = t(`LIVE_STREAMING_NOT_AVAILABLE`); //
  
      }else if(isCameraDisabled  && !isEdgeMode){
  
        cantPlayMessage = t(`CAMERA_DISABLED`);
        
      }else if(isCameraOffline){
  
        cantPlayMessage=t(`CAMERA_OFFLINE`)
      }
    }

    if(isEdgeMode && device?.showOnvifRemoteTrack != 1 ){

      cantPlayMessage = t(`EDGE_MODE_NOT_ALLOWED`);

    }

    // if(useWebRTC && device?.["is-fisheye"] === 1){

    //     cantPlayMessage=t(`FISHING_CAMERA_ERROR`)
    // }


    
    const hidePrivacyLayer = origin ==='popup' ? true : hidePrivacy;

    if(cantPlayMessage !== ''){
      return (
        <span className='arteco-player' ref={this.nodeRef} >
          <div className={`clappr offline-camera ${this.props.className}`}>
            <PlayerContextMenu 
            artecoId={this.props.artecoId} 
            isPlayback={this.props.isPlayback} 
            />
            <div className="errors">
              <p>{cantPlayMessage}</p>
            </div>
          </div>
        </span>
      )
    }

    if ((useWebRTC && !this.gotPlaylist() && timelineMode == undefined) || (useWebRTC && timelineMode == 'play' || useWebRTC && timelineMode == 'pause')) {
      // vado in webrtc se non ho playlist, se ho playlist devo fare il fallback su hls
      const playbackClass = isPlayback ? 'playback' : '';
      const edgeModeClass = isEdgeMode ? 'edge-mode' : '';
      return ( // fallback in webrtc, per il momento è un componente a parte
        <span className={`arteco-player ${playbackClass} ${edgeModeClass}`} ref={this.nodeRef}>
          <PlayerWebRTC_Styled
            index={index}
            key={`webRTC_live_video_${artecoId}`}
            artecoId={artecoId}
            className={`webrtc-player ${playbackClass} ${edgeModeClass}`}
            rtspUrl={rtspUrl}
            isPlayback={isPlayback ? isPlayback : false}
            hidePrivacy={hidePrivacyLayer}
            isPrivacyPopUpOn={isPrivacyPopUpOn}
            
          />
        </span>)
    }


    let playlistErrorMessage = '';
    const gotPlaylistError = !useWebRTC && this.gotPlaylistError() && notStopOrUndefined;
    const gotMaxPlaylistAttempt = !useWebRTC && this.gotPlaylistError() && notStopOrUndefined;

    if(gotPlaylistError){

      playlistErrorMessage = t(`PLAY_ERROR_${playlistError}`);

    }else if (gotMaxPlaylistAttempt){

      playlistErrorMessage = t(`REACHED_MAX_PLAY_FAILURE`);

    }
    
    if (playlistErrorMessage !=='') {
      //ricevuto un errore da parte della playlist
      return (
        <span className='arteco-player' ref={this.nodeRef}>
          <div key={device.artecoId} className={`clappr offline-camera ${cleanedClass}`}>
            {!hideContextMenu &&
              <PlayerContextMenu 
              artecoId={artecoId}
              isPlayback={this.props.isPlayback} 
              />
            }
            <div className="errors">
                <div>{playlistErrorMessage}</div> 
                {
                  (gotPlaylistError && allowBackToLive) && (
                    <button className="back-to-live btn" onClick={this.switchToLive}>{t('BACK_LIVE_LB')}</button>
                  )
                }
              </div>
          </div>
        </span>
      )
    }

    if (timelineMode == 'stop') {
      
      const chHasPrivacy = device?.privacyPlgEnabled ;
      let shouldIBlur = chHasPrivacy;
    
    if(chHasPrivacy){
      const hasPrivacyCredential = isCredentialEnabled(CamerasPrivacyBlur,device);
      shouldIBlur = hasPrivacyCredential ? this.props.channelPrivacyShown : true;
    }
      const isEdgeSearchClass = isEdgeMode ? 'edge-mode' : '';

      return (

        <div key={artecoId} className={`event-info-container arteco-player ${isEdgeSearchClass}`}>
          <PlayerContextMenu
            artecoId={artecoId}
            additionalClass={'playback'}
            isEdgeMode={isEdgeMode}
            isPlayback={this.props.isPlayback} 
          />
          {
            (eventImage != undefined) && (
              <ImageLazyLoad
                src={eventImage}
                alt={'event-image'}
                token={server.access_token}
                threshold={0.3}
                placeholderSrc="/sample/image-placeholder.png"
                fallbackImage={fallbackImage}
              />
            )
          }
          {shouldIBlur ? <PrivacyDisplay shouldAddPrivacyLayer={shouldIBlur} />:<></>}
        </div>

      )
    }


    if (currentPlaylist) {
      // caso in cui la richiesta proviene da un orario specifico

      //questa è la vera playlist corrente, la copia che c'è nello state è vecchia!
      const updatedPlaylist = this.props.playlists.find(playlist => playlist.playlistId === currentPlaylist.playlistId);
      return (
        <PunctualPlayer
          key={key}
          device={device}
          isPopup={isPopup}
          allowOnlyPIP={allowOnlyPIP}
          playerClass={playerClass}
          hideContextMenu={hideContextMenu}
          disableZoom={disableZoom}
          // props valide solo per il registrato
          allowBackToLive={allowBackToLive}
          liveErrors={this.handleLiveErrors}
          reachedEndOfVideo={this.reachedEndOfVideo} // raggiunta fine del video hls
          requestNextPlaylist={this.requestNextPlaylist} // richiesta di una nuova playlists
          secondsBeforeRequest={this.secondsBeforeRequest} // soglia di richiesta della playlist
          maxVideoDuration={this.playlistFetchInterval} // durata massima dei video
          forcePlayBackMode={forcePlayBackMode}
          timelineMode={timelineMode}
          playlist={currentPlaylist}
          autoFetchOnEnd={autoFetchOnEnd ? autoFetchOnEnd : false}
          metadataHistory={currentPlaylist.metadataHistory}
          onvifMetadataHistory={updatedPlaylist && updatedPlaylist.onvifMetadataHistory}
          hidePrivacy={hidePrivacyLayer}
          isPrivacyPopUpOn={isPrivacyPopUpOn}

        />
      );
    }

    if (this.props.ytId != undefined) {
      // don't tell anyone
      return (
        <iframe
          width="100%"
          height="100%"
          autoplay="1"
          src={`https://www.youtube.com/embed/${this.props.ytId}?autoplay=1&mute=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      )
    }

    return (

      // caso classico, form e to oppure live
      <ClapprPlayer
        key={key}
        device={device}
        isPopup={isPopup}
        allowOnlyPIP={allowOnlyPIP}
        source={recMedia || liveMedia}
        playerClass={playerClass}
        hideContextMenu={hideContextMenu}
        disableZoom={disableZoom}
        // props valide solo per il registrato
        playlistId={playlistId}
        instantInterval={{ from, to, }}
        allowBackToLive={allowBackToLive}
        isTranscoding={playlist?.isTranscoding}
        liveErrors={this.handleLiveErrors}
        reachedEndOfVideo={this.reachedEndOfVideo} // raggiunta fine del video hls
        requestNextPlaylist={this.requestNextPlaylist} // richiesta di una nuova playlists
        secondsBeforeRequest={this.secondsBeforeRequest} // soglia di richiesta della playlist
        maxVideoDuration={this.playlistFetchInterval} // durata massima dei video
        forcePlayBackMode={forcePlayBackMode}
        timelineMode={timelineMode}
        autoFetchOnEnd={autoFetchOnEnd ? autoFetchOnEnd : false}
        metadataHistory={metadataHistory}
        onvifMetadataHistory={onvifMetadataHistory}
        playlist={playlist}
        hidePrivacy={hidePrivacyLayer}
        origin={origin}
        marker={marker}
        isPrivacyPopUpOn={isPrivacyPopUpOn}
      />
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPlaylist: (fetchRequest) => dispatch(fetchPlaylist(fetchRequest)),
    setChannelMode: (mode, artecoId) => dispatch(setChannelMode(mode, artecoId)),
    forceLogin: (server) => dispatch(forceLogin(server)),
    updatePlaylistStatus: (newData) => dispatch(updatePlaylistStatus(newData)),
    setTimelineMode: (command) => dispatch(setTimelineMode(command)),
    resetPlaylistsErrorByArtecoId: (artecoId) => dispatch(resetPlaylistsErrorByArtecoId(artecoId)),
    dispatch
  }
}


const mapStateToProps = (state, ownProps) => {

  const playlistId = (ownProps.artecoId && ownProps.from && ownProps.to) ? `${ownProps.artecoId}_${ownProps.from}_${ownProps.to}` : undefined;
  const server = getParentServerByArtecoId(state, ownProps.artecoId);
  const device = getDeviceByArtecoId(state, ownProps.artecoId);
  const playlist = getPlaylistById(state, playlistId);
  const playlistError = getPlaylistError(state, ownProps.artecoId);
  const playlists = getPlaylistsByArtecoId(state, ownProps.artecoId);
  const liveMedia = (device) ? getMediaUri(state, device.serverCodename, device.hlsStream) : undefined;
  const recMedia = (playlist?.recPath) ? getMediaUri(state, device.serverCodename, playlist?.recPath) : undefined;
  const metadataHistory = (playlist?.recPath) ? playlist.metadataHistory : undefined;
  const onvifMetadataHistory = (playlist?.recPath) ? playlist.onvifMetadataHistory : undefined;
  const isEdgeMode = getEdgeMode(state);
  const selectedTime = getTimeLineMarker(state);
  const channelMode = getChannelMode(state, ownProps.artecoId);
  const isChRunning = (device) ? getChannelRunningStatus(device.serverCodename, device.artecoId) : null;
  return {
    server,
    playlist,
    device,
    playlistError,
    playlistId,
    liveMedia,
    recMedia,
    playlists,
    metadataHistory,
    onvifMetadataHistory,
    isEdgeMode,
    selectedTime,
    channelMode,
    isChRunning,
    channelPrivacyShown: getChannelPrivacyShown(state, ownProps.artecoId),
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ArtecoPlayer));

