import axios from "axios";

import { appPaths } from "../config/paths";
import { logger, error, info } from "../helpers/logger";

import {
  SET_ERRORS,
  SERVER_REMOVED,
  SERVER_ADDED,
  SET_CURRENT_SERVER,
  FORCE_LOGIN,
  STOP_LOGIN,
  FORCE_LOGIN_CODENAME,
  FORCE_LOGOUT,
  FORCE_LOGOUT_CODENAME,
  SERVER_DISCONNECT,
  SERVER_DISCONNECT_ALL,
  FORCE_LOGIN_ALL,
  SERVER_TREE_TOGGLE,
  SERVER_TREE_TOGGLE_ALL,
  SET_SERVER_IS_LOCAL,
  SET_SERVER_IS_OFFLINE,
  UPDATE_SERVER,  
  SERVER_UPDATE_SOCKET_STATUS,
  INIT_PERIPHERAL,
  SET_PERIPHERAL,
  PTZ_IS_CHANGING,
  CHANNEL_IS_SET,
  CHANNEL_IS_SETTING,
  LINKED_PERIPHERALS,
  CLOSE_LINKED_PERIPHERALS,
  CHANNEL_UPDATE,
  CHANNEL_ADD,
  CHANNEL_REMOVE,
  LAYOUT_CHANNEL_REMOVE,
  ADD_NEW_CHANNEL_SUCSESS,
  ADD_NEW_CHANNEL_PENDING,
  ADD_NEW_CHANNEL_ERROR,
  CHANNEL_ADD_PROPERTY,
  TAGS_REMOVE,
  FILTER_SERVERS_WITHOUT_LICENCE,
  DUPLICATE_SERIAL_SERVER_REMOVE,  
  SHOW_SITES_INFORMATION_POPUP,
  UPDATE_TIME_MISMATCH_SERVER,
  LOGIN_SUCCESS,
  ABORT_LOGIN
} from "./types";
import { fetchServersSuccess } from "./fetchServers";
import { removeMapsFromThisServer } from "./mapsActions";
import { removeDomesFromThisServer } from "./domesActions";
import { getSyncServers, isServerInSyncServers } from "../reducers/serversReducer";

export const addServer = (newServer, history, callback) => (dispatch) => {
  const shouldRedirect = !!history;

  axios
    .post("/api/servers/insert", newServer)
    .then((res) => {
      dispatch({
        type: SERVER_ADDED,
        payload: res.data,
      });
    })
    .then((res) => {
      if (shouldRedirect) history.push(appPaths.dashboard);
      callback && callback();
    })
    .catch((err) =>
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getServerData = (serverId) => (dispatch) => {
  axios
    .post("/api/servers/get-by-id", {
      _id: serverId,
    })
    .then((res) => {
      dispatch({
        type: SET_CURRENT_SERVER,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      })
    );
};

let isSyncing = false;

// function to delete duplicates servers. 
// in case we have two servers with the same serial number, we should delete the one without access_token
// if both servers have access_token or if both servers don't have access_token, we should keep the older one
// the array serversToRemove is used to store the servers that will be removed and should be returned to the caller
export const deleteDuplicates = (originalServers, serversToRemove) => {
  const servers = originalServers;
  const serversToDelete = [];

  servers.forEach((server, index) => {
    const serverIndex = servers.findIndex(
      (s) => s.license?.serial === server.license?.serial
    );

    if (serverIndex !== index) {
      const serverToDelete = servers[serverIndex];
      const serverToDeleteHasToken = !!serverToDelete.access_token;
      const serverHasToken = !!server.access_token;

      if (serverToDeleteHasToken === serverHasToken) {
        serversToDelete.push(serverToDelete);
      } else if (serverToDeleteHasToken) {
        serversToDelete.push(server);
      } else {
        serversToDelete.push(serverToDelete);
      }
    }
  });

  serversToRemove.push(...serversToDelete);

  const outputServers = servers.filter(
    (server) => !serversToDelete.find((s) => s._id === server._id)
  );

  return { outputServers, serversToRemove };
}

export const alignServersToLicenseService = (sites, user) => async (dispatch) => {  
  if(isSyncing) return;
  isSyncing = true;

  //retrieve a fresh copy of servers from DB, waiting for the result  
  let originalServers = [];
  let queryErrors = false;

  try {
    const res = await axios.post("/api/servers/get-by-user", { owner: user.id });

    if (res.error) {
      throw res.error;
    }

    originalServers = res.data;
  } catch (error) {
    queryErrors = true;
    dispatch({
      type: SET_ERRORS,
      payload: error,
    });

    isSyncing = false;
  }

  if(queryErrors) {
    return;
  }

  //translate sites to a more confortable data structure
  const serverWithSites = {};
  sites.forEach(site => {
      site.server_in_site.forEach(server => {
        const key = server.serialno.toUpperCase();

        const richServer = {
          ...server,
          siteInfo: site.site_info
        };

        serverWithSites[key] = richServer
      })
  })



  
  //delete duplicates servers.
  const duplicatedServers = [];
  const result = deleteDuplicates(originalServers, duplicatedServers);
  
  const serversToRemove = [];
  const serversToUpdate = [];
  const serversToAdd = [];

  const serversToAutologin = [];

  const servers = result.outputServers;

  servers && servers.forEach(server => {
    const serial = server.license?.serial?.toUpperCase();
    const freshServer = serverWithSites[serial];

    if(!freshServer) {
      serversToRemove.push(server);
      dispatch(removeMapsFromThisServer({
        owner: user.id,              
        serverId: server._id,
        serverCodename: server.codeName
      }));
  
      dispatch(removeDomesFromThisServer({
        owner: user.id,              
        serverId: server._id,
        serverCodename: server.codeName
      }));
    } else {      

      const passwordVersion = freshServer.siteInfo.password_ver ?  parseInt(freshServer.siteInfo.password_ver) : 0;
      const currentPasswordVersion = server.password_ver ?  parseInt(server.password_ver) : 0;
  
      const passwordWillChange = passwordVersion > currentPasswordVersion;      
      
      const freshPort = freshServer.site_port;
      const portWillChange = freshPort !== server.port;      
  
      if(passwordWillChange || portWillChange) {
        serversToAutologin.push(serial);
      }

      serversToUpdate.push({
        ...server,  
        omnia_web_connection: freshServer.omnia_web_connection,      
        port: freshPort ? freshPort : server.port,
        licenseType: freshServer.license_type && freshServer.license_type.toLowerCase(), //it's license_type (with the _) on WP
        password_ver: passwordVersion,
        password: passwordWillChange ? freshServer.siteInfo.password_arteco_server : server.password,
        siteInfo: freshServer.siteInfo
      });

      delete serverWithSites[serial];
    }
       
  })  
  
  //check if something remains in the downloaded list
  const theresServersToAdd = Object.values(serverWithSites)?.length;

  if(theresServersToAdd) {
    Object.values(serverWithSites).forEach(server => {

      //check if the server is for some reason already added
      const alreadyAdded = serversToAdd.find(s => s.license.serial.toUpperCase() === server.serialno.toUpperCase());
      const alreadyUpdated = serversToUpdate.find(s => s.license.serial.toUpperCase() === server.serialno.toUpperCase());

      if(!alreadyAdded && ! alreadyUpdated) {
        const passwordVersion = server.siteInfo.password_ver ?  parseInt(server.siteInfo.password_ver) : 0;
  
        const serverToAdd = {
          ...server,
          owner: user.id,
          ip: `${server.serialno}${appPaths.PUBLIC_HOST}`,
          port: server.site_port,
          protocol: 'https',
          uptodate: false,
          username: server.siteInfo.username_arteco_server,
          password: server.siteInfo.password_arteco_server,
          password_ver: passwordVersion,
          licenseType: server.license_type && server.license_type.toLowerCase(),
          license: {
            serial: server.serialno
          },
          siteInfo: server.siteInfo
        }
        
        if(serverToAdd.password) {
          serversToAutologin.push(serverToAdd.license.serial);
        }
        serversToAdd.push(serverToAdd);      
      }
    });
  }  

  const updateInstructions = {
    toUpdate: serversToUpdate,
    toAdd: serversToAdd,
    toDelete: serversToRemove,
    duplicated: duplicatedServers,
    owner: user.id
  }
  
  axios
  .post("/api/servers/sync-servers", updateInstructions)
  .then((res) => {
    const { data } = res;

    if(data.success && data.updatedServers) {
      
      //process data before updating the state
      let updatedServerCollection = data.updatedServers.map(server => {
          const updatedServer = server;
          const wasUpdated = (serversToUpdate.length > 0) && serversToUpdate.find(original => original._id === server._id);

          //additional info due to business logic
          if(serversToAutologin.includes(server.license.serial)) {
            updatedServer.needsRelogin = true;
          }

          //recover information not stored on DB!
          if(wasUpdated){
            updatedServer.uptodate = wasUpdated.uptodate;
            updatedServer.socketAttached = wasUpdated.socketAttached;
            updatedServer.connectionStatus = wasUpdated.connectionStatus;            
          }

          return server;
        });

        //check for duplicate servers
        let serverList = updatedServerCollection;
        let serversToRemove = [];
        const checkResult = deleteDuplicates(serverList, serversToRemove);
        let outputServers = checkResult.outputServers;        

        if (serversToRemove.length > 0) {
          logger(error, "syncServer", 'duplicate servers for user ' + user.email + ' found - AFTER SYNC');

          // no need to remove servers from the store, cause we'll replace the server list with the new one
          // const serversToRemoveIds = serversToRemove.map(server => server._id);
          // dispatch({
          //   type: SERVERS_REMOVED,
          //   payload: {
          //     serverList: serversToRemoveIds
          //   }
          // })

          dispatch(fetchServersSuccess({servers: outputServers, isSync: true}));  
        } else {
          dispatch(fetchServersSuccess({servers: res.data.updatedServers, isSync: true}));
        }  
      
      dispatch({
        type: SHOW_SITES_INFORMATION_POPUP,
        payload:{
          showSitesInformationPopup: true,
          serverNotAuthorzied: false,
          serverUsernameFiledEmpty: false,
          serverPasswordFieldEmpty: false
        }
      })
    } else {
      return dispatch({
        type: SET_ERRORS,
        payload: data.message,
      });
    }

    isSyncing = false;
  })
  .catch((err) => {
    isSyncing = false;
    return dispatch({
      type: SET_ERRORS,
      payload: err.message,
    });
  });
}

export const updateServer =
  (updatedServer, history, callback) => (dispatch) => {
    const shouldRedirect = !!history;

    axios
      .post("/api/servers/update/", updatedServer)
      .then((res) => {
        dispatch({
          type: SERVER_DISCONNECT,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_SERVER,
          payload: {
            ...res.data,
            needsRelogin: updatedServer.needsRelogin,
            icon: updatedServer.icon
          },
        });

        if (shouldRedirect) history.push(appPaths.dashboard);
        callback && callback();
      })
      .catch((err) => {
        return dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      });
  };

  
export const removeServer = (serverId) => (dispatch) => {
  axios
    .delete("/api/servers/delete/" + serverId)
    .then((res) => {
      dispatch({
        type: SERVER_REMOVED,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) =>
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const duplicateSerialServerRemove = (serverId) => (dispatch) => {
  axios
    .delete("/api/servers/delete/" + serverId)
    .then((res) => {
      dispatch({
        type: DUPLICATE_SERIAL_SERVER_REMOVE,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) =>
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const forceLogin = (serverId) => (dispatch) => {
  logger(info, ">>>>>> 1020 server", "Demo ForceLogin");
  dispatch({
    type: FORCE_LOGIN,
    payload: serverId,
  });
};

export const stopLogin = (serverId) => (dispatch) => {
  dispatch({
    type: STOP_LOGIN,
    payload: serverId,
  });
};

export const forceLoginByCodename = (serverCodename) => (dispatch) => {
  dispatch({
    type: FORCE_LOGIN_CODENAME,
    payload: serverCodename,
  });
};

export const forceLogout = (serverId) => (dispatch) => {
  dispatch({
    type: FORCE_LOGOUT,
    payload: serverId,
  });
};

export const forceLogoutByCodename = (serverCodename) => (dispatch) => {
  dispatch({
    type: FORCE_LOGOUT_CODENAME,
    payload: serverCodename,
  });
};

export const forceLoginAll = (owner) => (dispatch) => {
  dispatch({
    type: FORCE_LOGIN_ALL,
    payload: owner,
  });
};

export const serverTreeToggle =
  (serverId, serverTreeExpanded, serverTreeExpandedRecursively) =>
  (dispatch) => {
    dispatch({
      type: SERVER_TREE_TOGGLE,
      payload: {
        serverId,
        serverTreeExpanded,
        serverTreeExpandedRecursively,
      },
    });
  };

export const serverTreeToggleAll = (mode, recursive) => (dispatch) => {
  dispatch({
    type: SERVER_TREE_TOGGLE_ALL,
    payload: {
      serverTreeExpanded: mode,
      serverTreeExpandedRecursively: recursive,
    },
  });
};

export const setServerIsLocal = (server) => (dispatch) => {
  logger(
    info,
    "connection",
    ">>> server " + server.name + " set server is local"
  );
  dispatch({
    type: SET_SERVER_IS_LOCAL,
    payload: {
      serverId: server._id,
      isLocal: server.isLocal,
    },
  });
};

export const setServerOffline = (server) => (dispatch) => {
  dispatch({
    type: SET_SERVER_IS_OFFLINE,
    payload: {
      serverId: server._id,
      offline: true,
    },
  });
};

export const setServerBackOnline = (server) => (dispatch) => {
  dispatch({
    type: SET_SERVER_IS_OFFLINE,
    payload: {
      serverId: server?._id,
      offline: false,
    },
  });
};

export const updateSocketStatus = (_id, codeName, socketAttached) => (dispatch) => {
  //const needsRelogin = socketAttached && !isServerInSyncServers(codeName)  //questo caso dovrebbe essere stato risolto dal lavoro sul DISCONNECT_ALL (AST-5533)
  const needsRelogin = false;
  dispatch({
    type: SERVER_UPDATE_SOCKET_STATUS,
    payload: {
      _id,
      socketAttached,
      needsRelogin,
    },
  });
};

export const disconnectServer = (_id, codeName) => (dispatch) => {
  axios
    .post("/api/servers/update/", {
      _id,
      codeName,
      uptodate: false,
      mode: "disconnect",
    })
    .then((ans) => {
      dispatch({
        type: SERVER_DISCONNECT,
        payload: {
          _id: _id,
          codeName: codeName,
        },
      });
      removeServerFromSyncServers(codeName)
      removeCameraStatusByCodeName(codeName)
      
    });
};

export const disconnectAllServers = (owner, servers) => (dispatch) => {
    axios
    .post("/api/servers/disconnect-all/", {
      owner,
    })
    .then((ans) => {    
      dispatch({
        type: SERVER_DISCONNECT_ALL,
        payload: servers,
      });

      servers.forEach(server => {
        removeServerFromSyncServers(server.codeName)
        removeCameraStatusByCodeName(server.codeName)
      });
      
    });
  };

// export const updateConnectedUsers =
//   (servercodeName, userList) => (dispatch) => {
//     dispatch({
//       type: SERVER_UPDATE_USER_LIST,
//       payload: {
//         codeName: servercodeName,
//         userList: userList,
//       },
//     });
//   };

// export const updateUserPosition = (data) => (dispatch) => {
//   dispatch({
//     type: SERVER_UPDATE_USER_POSITION,
//     payload: data,
//   });
// };

export const addNewChannel = (data) => (dispatch) => {
  axios
    .post("/api/devices/add-channel/", data)
    .then((result) => {
        dispatch({
          type: ADD_NEW_CHANNEL_SUCSESS,
          payload: data,
        });
    })
    .catch((err) => {
      dispatch({
        type: ADD_NEW_CHANNEL_SUCSESS,
        payload: data,
      });
    });
};
export const addNewChannelPending = (data) => (dispatch) => {
  dispatch({
    type: ADD_NEW_CHANNEL_PENDING,
  });
};
export const addNewChannelError = (data) => (dispatch) => {
  dispatch({
    type: ADD_NEW_CHANNEL_ERROR,
    payload: data,
  });
};

export const setFilterServersWithOutLicence = () => (dispatch) => {
  dispatch({
    type: FILTER_SERVERS_WITHOUT_LICENCE,
  })
}

export const notifyDevice = (data) => (dispatch) => {
  if (data.lane) {
    switch (data.lane) {
      case "manageDevices":
        switch (data.ctx) {
          case "ptzCmd":
          case "ptzInfoCommand":
          case "ptzInfoPreset":
          case "ptzInfoSequence":
            dispatch({
              type: PTZ_IS_CHANGING,
              payload: data,
            });
            return true;

          case "getPrivacyLive":
            if(data.status !== "error") {
              dispatch({
                type: CHANNEL_IS_SET,
                payload: data,
              });
            }
          return true;
          case "chlSetConfig":
          // case "getPrivacyLive":
            if (data.status !== "error") {
              axios
                .post("/api/devices/update-channel/", data)
                .then((result) => {
                  if (result.data.ok) {
                    dispatch({
                      type: CHANNEL_IS_SET,
                      payload: data,
                    });
                  }
                })
                .catch((err) => {
                  logger(error, "server", err);
                });
            } else {
              dispatch({
                type: CHANNEL_IS_SET,
                payload: data,
              });
            }

            return true;

          case "chlSettingConfig":
            dispatch({
              type: CHANNEL_IS_SETTING,
              payload: data,
            });
            return true;
          case "chConfigChanged":
            axios
              .post("/api/devices/update-channel/", data)
              .then((result) => {
                if (result.data.ok) {
                  dispatch({
                    type: CHANNEL_UPDATE,
                    payload: data,
                  });
                }
              })
              .catch((err) => {
                logger(error, "server", err);
              });

            return true;

          case "chStarted":
          case "chStopped":
            updateCameraOnlineOfflineStatus(data)
            dispatch({
              type: CHANNEL_ADD_PROPERTY,
              payload: data
            })

            return true;

          case "chAdded":
            axios
              .post("/api/devices/add-channel/", data)
              .then((result) => {
                  dispatch({
                    type: CHANNEL_ADD,
                    payload: data,
                  });
                  dispatch({
                    type: LAYOUT_CHANNEL_REMOVE,
                    payload: data,
                  });
              })
              .catch((err) => {
                dispatch({
                  type: CHANNEL_ADD,
                  payload: data,
                });
                dispatch({
                  type: LAYOUT_CHANNEL_REMOVE,
                  payload: data,
                });
              });

              axios
              .delete(`/api/tags/remove-tags`, {
                data: data,
              })
              .then((result) => {
                if (result.status === 200) {
                  dispatch({
                    type: TAGS_REMOVE,
                    payload: data,
                  });
                }
              })
              .catch((err) => {
                logger(error, "server", err);
              });

            return true;
            

          case "chRemoved":
            const chId = data?.chId;
            const serverCodename = data?.serverCodename;
            const artecoId = data?.artecoId;
            axios
              .delete(`/api/devices/delete-channel/`, {
                data: { chId, serverCodename },
              })
              .then((result) => {
                if (result.data.ok) {
                  dispatch({
                    type: CHANNEL_REMOVE,
                    payload: data,
                  });
                }
              })
              .catch((err) => {
                logger(error, "server", err);
              });
              
              axios
              .delete(`/api/layouts/delete-channel`, {
                data: { artecoId },
              })
              .then((result) => {
                if (result.status === 200) {
                  dispatch({
                    type: LAYOUT_CHANNEL_REMOVE,
                    payload: data,
                  });
                }
              })
              .catch((err) => {
                logger(error, "server", err);
              });

              axios
              .delete(`/api/tags/remove-tags`, {
                data: { artecoId },
              })
              .then((result) => {
                if (result.status === 200) {
                  dispatch({
                    type: TAGS_REMOVE,
                    payload: data,
                  });
                }
              })
              .catch((err) => {
                logger(error, "server", err);
              });

            return true;

          default:
            return false;
        }

      default:
        return false;
    }
  } else {
    //peripherals
    if (data.type === "getPeripheral") {
      dispatch({
        type: INIT_PERIPHERAL,
        payload: data,
      });
    }

    if (data.type === "changedPeripheral") {
      dispatch({
        type: SET_PERIPHERAL,
        payload: data,
      });
    }
  }
};

export const addLinkedPeripheralsPopup = (linkedPeripherals) => (dispatch) => {
  //we could save this setting in LocalStorage
  dispatch({
    type: LINKED_PERIPHERALS,
    payload: linkedPeripherals,
  });
};

export const closeLinkedPeripheralsPopup = () => (dispatch) => {
  //we could save this setting in LocalStorage
  dispatch({
    type: CLOSE_LINKED_PERIPHERALS,
  });
};



// export const updateConnectedUsersAll =
//   (userList) => (dispatch) => {
//     dispatch({
//       type: UPDATE_USER_LIST,
//       payload: {
//         userList: userList        
//       },
//     });
//   };

export const updateServerTimeMisMatch = (serverId, timeMismatch) => (dispatch) => {
  dispatch({
    type: UPDATE_TIME_MISMATCH_SERVER,
    payload : {
      serverId,
      timeMismatch
    } 
  })

}

export const  updateCameraOnlineOfflineStatus = (cameraDetails) => {
  const localstorageCamerasString = localStorage.getItem('camerasOnlineOfflineStatus');
  const localstorageCameras = localstorageCamerasString ? JSON.parse(localstorageCamerasString) : {};

  const { serverCodename, artecoId, running } = cameraDetails;

  if (!localstorageCameras[serverCodename]) {
    localstorageCameras[serverCodename] = {};
  }
  localstorageCameras[serverCodename][artecoId] = running;

  localStorage.setItem('camerasOnlineOfflineStatus', JSON.stringify(localstorageCameras));
}

export const removeCameraStatusByCodeName = (serverCodeName) => {
  const localstorageCamerasString = localStorage.getItem('camerasOnlineOfflineStatus');
  if (localstorageCamerasString) {
    const localstorageCameras = JSON.parse(localstorageCamerasString);
    delete localstorageCameras[serverCodeName];
    localStorage.setItem('camerasOnlineOfflineStatus', JSON.stringify(localstorageCameras));
  }
}

export const addServerToSyncServers = (newServerCodeName) => {
  const syncServers = getSyncServers();
  if (!syncServers.includes(newServerCodeName)) {
    const updatedServers = [...syncServers, newServerCodeName];
    localStorage.setItem('syncServers', JSON.stringify(updatedServers));
  }
}

export const  removeServerFromSyncServers = (serverCodeNameToRemove) => {
  const syncServers = getSyncServers();
  if (syncServers.includes(serverCodeNameToRemove)) {
    const updatedServers = syncServers.filter(serverCodeName => serverCodeName !== serverCodeNameToRemove);
    localStorage.setItem('syncServers', JSON.stringify(updatedServers));
  }
}

export const completeLogin = (codeName) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload : {
      codeName: codeName
    } 
  })
}

export const abortLogin = (codeName) => (dispatch) => {
  dispatch({
    type: ABORT_LOGIN,
    payload : {
      codeName: codeName
    } 
  })
}


